import { Stack } from '@mui/material';
import { theme, Typography } from '@imago-cloud/design-system';
import { TMeditCase } from '../../Shared/Types/meditCaseType';
import { useMeditCaseState } from '../../Shared/Atoms/meditCase/useMeditCaseState';

export const MeditCaseListItem = (props: TMeditCase) => {
  const { setMeditCase } = useMeditCaseState();

  const onCaseClick = () => {
    setMeditCase(props);
  };

  return (
    <Stack
      direction="row"
      sx={{
        minHeight: '60px',
        justifyContent: 'space-between',
        height: '60px',
        pl: '20px',
        pr: '32px',
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.action.hover
        },
        '&:active': {
          backgroundColor: 'grey.200'
        }
      }}
      alignItems="center"
      onClick={onCaseClick}
    >
      <Typography
        variant="Subtitle_Semibold14"
        sx={{
          width: '148px',
          height: '36px',
          backgroundColor: 'blue.10',
          color: 'blue.500',
          borderRadius: '6px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {convertStatusName(props.status)}
      </Typography>
      <Typography
        variant="Body16"
        sx={{
          width: '480px'
        }}
      >
        {props.name}
      </Typography>
      <Typography
        variant="Body16"
        sx={{
          width: '320px'
        }}
      >
        {props.patient.name}
      </Typography>
    </Stack>
  );
};

const convertStatusName = (statusName: string) => {
  switch (statusName) {
    case 'NEED_PROCESSING':
      return 'Need Processing';
    case 'COMPLETED':
      return 'Completed';
    default:
      return statusName;
  }
};
