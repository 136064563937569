import { Typography } from '@imago-cloud/design-system';
import { useTranslation } from 'react-i18next';

export const CaseListDialogueTitle = () => {
  const { t } = useTranslation();
  return (
    <Typography variant={'H18'}>
      {t('meditLink:case_selection.dialog_title')}
    </Typography>
  );
};
