import { Button, DialogueTitle, Typography } from '@imago-cloud/design-system';
import { FileListDialogueTitle } from './Components/FileListDialogueTitle';
import { useMeditCaseState } from '../Shared/Atoms/meditCase/useMeditCaseState';
import { Stack } from '@mui/material';
import { useMeditUserInfoState } from '../Shared/Atoms/meditUserInfo/useMeditUserInfoState';
import { CaseListDialogueTitle } from './Components/CaseListDialogueTitle';
import { CaseListHeader } from './Components/CaseListHeader';
import { useTranslation } from 'react-i18next';

export const MeditLinkImporterHeader = ({
  logoutMeditLink
}: {
  logoutMeditLink: () => Promise<void>;
}) => {
  const { meditCase, setMeditCase } = useMeditCaseState();
  const { meditUserInfo } = useMeditUserInfoState();
  const { t } = useTranslation();

  const onLogoutClick = async () => {
    await logoutMeditLink();
    setMeditCase(null);
  };

  return (
    <>
      <DialogueTitle
        sx={{
          pb: '12px',
          '& > div': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }
        }}
      >
        {!meditCase ? <CaseListDialogueTitle /> : <FileListDialogueTitle />}
        <Stack
          sx={{
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography variant={'Body16'}>{meditUserInfo.email}</Typography>
          <Button
            size={'36'}
            color={'grey'}
            variant={'outlined'}
            onClick={onLogoutClick}
          >
            {t('meditLink:case_selection.btn_log_out')}
          </Button>
        </Stack>
      </DialogueTitle>
      {!meditCase && <CaseListHeader />}
    </>
  );
};
