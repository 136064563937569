import { meditSelectedFileListState } from '@Features/MeditLink/Shared/Atoms/meditSelectedFileList/atom';
import { useRecoilState } from 'recoil';

export const useMeditSelectedFileListState = () => {
  const [fileList, setFileList] = useRecoilState(meditSelectedFileListState);

  const appendFileToList = (uuid: string) => {
    setFileList((prev) => [...prev, uuid]);
  };

  const deleteFileFromList = (uuid: string) => {
    setFileList((prev) => prev.filter((_uuid) => _uuid !== uuid));
  };

  return {
    fileList,
    setFileList,
    appendFileToList,
    deleteFileFromList
  };
};
