import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { IThemeProvider } from './IthemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Routers from './Routes';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from './Shared/Components/ErrorBoundary';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ModuleProvider, ModuleProviderProps } from '@imago/imago-modules';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

function App() {
  return (
    <>
      <HelmetProvider>
        <ModuleProvider
          config={{
            env: process.env
              .REACT_APP_ENVIRONMENT as ModuleProviderProps['config']['env']
          }}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <RecoilRoot>
              <RecoilNexus />
              <IThemeProvider>
                <ErrorBoundary>
                  <Router>
                    <Routers />
                  </Router>
                </ErrorBoundary>
              </IThemeProvider>
            </RecoilRoot>
          </QueryClientProvider>
        </ModuleProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
