import { ReactNode } from 'react';
import { useErrorState } from '../../Atoms/Error/useErrorState';
import { ERROR_TYPE } from '../../Constants/errorType';
import { UnknownErrorDialogue } from '../UnknownErrorDialogue';

const ErrorFallback = {
  [ERROR_TYPE.UNKNOWN]: <UnknownErrorDialogue />
};

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { error } = useErrorState();

  if (error.isError)
    return ErrorFallback[error.errorType ?? ERROR_TYPE.UNKNOWN];
  else {
    return <>{children}</>;
  }
};
