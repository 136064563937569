import { useMeditCaseState } from '../../Shared/Atoms/meditCase/useMeditCaseState';
import { Stack } from '@mui/material';
import { IconButton, Ricon, Typography } from '@imago-cloud/design-system';
import { useMeditSelectedFileListState } from '../../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';

export const FileListDialogueTitle = () => {
  const { meditCase, setMeditCase } = useMeditCaseState();
  const { setFileList } = useMeditSelectedFileListState();

  const onBackClick = () => {
    setMeditCase(null);
    setFileList([]);
  };

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center'
      }}
    >
      <IconButton
        size={'32'}
        color={'grey800'}
        icon_size={'small'}
        hover_color={'black'}
        variant={'outlined'}
        onClick={onBackClick}
      >
        <Ricon icon={'ri-arrow-left-s-line'} />
      </IconButton>
      <Typography variant={'H18'}>{meditCase?.name ?? ''}</Typography>
    </Stack>
  );
};
