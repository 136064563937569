import { useRecoilState } from 'recoil';
import { meditUserInfoState } from './atom';
import { TMeditUserWithToken } from '../../Types/meditUserType';
import { setMeditAxiosInstanceHeader } from '../../Apis/meditAxiosInstance';

export const useMeditUserInfoState = () => {
  const [meditUserInfo, setMeditUserInfo] = useRecoilState(meditUserInfoState);

  const setMeditUserInfoWithAxiosHeader = ({
    accessToken,
    groupUUID,
    userInfo
  }: TMeditUserWithToken) => {
    setMeditAxiosInstanceHeader({ accessToken, groupUUID });
    setMeditUserInfo(userInfo);
  };
  return { meditUserInfo, setMeditUserInfo, setMeditUserInfoWithAxiosHeader };
};
