import { useState } from 'react';

export const useDialogue = () => {
  const [open, setOpen] = useState(false);

  const handleOpenDialogue = () => {
    setOpen(true);
  };

  const handleCloseDialogue = () => {
    setOpen(false);
  };

  return { open, handleOpenDialogue, handleCloseDialogue };
};
