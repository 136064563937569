import { useGetUserInfo } from '@Shared/Apis/user';
import {
  getUserInfoAndTokenUsingCode,
  getUserInfoAndTokenUsingRefreshToken
} from '../Apis/shining3dAuthApis';
import { openShining3DWindow } from '../Utils/openShining3DWindow';
import {
  OAUTH_ACCESS_TOKEN,
  OAUTH_REFRESH_TOKEN
} from '../../Shared/Constants/cookies';
import { useState } from 'react';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useShining3DCookies } from '../../Shared/Hooks/useShining3DCookies';
import { useShining3DUserInfoState } from '../../Shared/Atoms/shining3DUserInfo/useShining3DUserInfoState';

export const useShining3DLogin = () => {
  const { data } = useGetUserInfo();
  const [isLoginProcessing, setIsLoginProcessing] = useState(false);
  const { setUserInfo } = useShining3DUserInfoState();
  const { callbackCode, refreshToken, setCookies, removeNonAuthCookies } =
    useShining3DCookies();

  const setUserInfoAndTokens = ({ result }: { result: any }) => {
    setCookies([
      { cookieName: OAUTH_REFRESH_TOKEN, cookieValue: result.refreshToken },
      { cookieName: OAUTH_ACCESS_TOKEN, cookieValue: result.accessToken }
    ]);
    setUserInfo({ email: result.email, factories: result.factories });
    setIsLoginProcessing(false);
  };

  const loginToShining3DUsingRefreshToken = async () => {
    try {
      setUserInfoAndTokens(
        await getUserInfoAndTokenUsingRefreshToken(refreshToken)
      );
    } catch (err) {
      removeNonAuthCookies();
      setIsLoginProcessing(true);
      openShining3DWindow(data?.user.email, data?.user.fullName);
    }
  };

  const loginToShining3DUsingCallbackCode = async () => {
    try {
      setUserInfoAndTokens(await getUserInfoAndTokenUsingCode(callbackCode));
    } catch (err) {
      alert('Please try again.');
      removeNonAuthCookies();
    }
  };

  const cancelLoginProcess = () => {
    setIsLoginProcessing(false);
    onCloseApp();
  };

  return {
    needLoading: !data,
    isLoginProcessing,
    loginToShining3DUsingRefreshToken,
    cancelLoginProcess,
    loginToShining3DUsingCallbackCode
  };
};
