import { Stack } from '@mui/material';
import { WarningIcon } from '@Shared/Components/WarningIcon/WarningIcon';
import { Typography } from '@imago-cloud/design-system';
import { useTranslation } from 'react-i18next';

export const NoFileListContent = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px'
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'center'
        }}
      >
        <WarningIcon />
        <Typography variant={'H24'}>
          {t('meditLink:no_data.error_title_no_data')}
        </Typography>
      </Stack>
    </Stack>
  );
};
