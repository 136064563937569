import { theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InstitutionListHeader = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        py: '15px',
        pl: '40px',
        color: 'grey.800',
        gap: '20px',
        borderBottom: '1px solid',
        borderColor: theme.palette.divider
      }}
    >
      <Typography
        variant={'Subtitle_Semibold14'}
        sx={{
          width: '240px'
        }}
      >
        {t('shining3D:select_institution.list_header_organization_type')}
      </Typography>
      <Typography variant={'Subtitle_Semibold14'}>
        {t('shining3D:select_institution.list_header_institution')}
      </Typography>
    </Stack>
  );
};
