import produce from 'immer';
import { useForm } from 'react-hook-form';
import { IListContextBase } from '../../Shared/Types/listContext';
import { TDesignCase } from '../../Shared/Types/designCase';

export const useListSearchFilter = (
  context: IListContextBase<TDesignCase | null>
) => {
  const { queryOption, setQueryOption, setSelectedItem } = context;
  const { control, resetField, handleSubmit } = useForm({
    defaultValues: {
      search: ''
    }
  });
  const clearField = () => {
    resetField('search');
  };

  const handleSearch = (value: { search: string }) => {
    const newQueryOption = produce(queryOption, (draft: { search: string }) => {
      draft.search = encodeURIComponent(value.search);
    });

    setQueryOption(newQueryOption);
    setSelectedItem(null);
  };

  const onSubmit = handleSubmit(handleSearch);

  return { control, clearField, onSubmit };
};
