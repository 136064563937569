import { Dayjs } from 'dayjs';
import produce from 'immer';
import { IListContextBase } from '../../Shared/Types/listContext';
import { TDesignCase } from '../../Shared/Types/designCase';

export const useListDateFilter = (
  context: IListContextBase<TDesignCase | null>
) => {
  const { queryOption, setQueryOption } = context;

  const handleDateChange = (value: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }) => {
    const { startDate, endDate } = value;
    const newQueryOption = produce(queryOption, (draft) => {
      draft.date = {
        startDate: startDate ? startDate.startOf('day') : null,
        endDate: endDate ? endDate.endOf('day').add(1, 'days') : null
      };
    });

    setQueryOption(newQueryOption);
  };

  return { handleDateChange };
};
