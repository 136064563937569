import { useShining3DLogout } from '../Hooks/useShining3DLogout';
import { useShining3DUserInfoState } from '../../Shared/Atoms/shining3DUserInfo/useShining3DUserInfoState';
import { Button, DialogueTitle, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InstitutionTitle = () => {
  const { logoutShining3D } = useShining3DLogout();
  const { userInfo } = useShining3DUserInfoState();
  const { t } = useTranslation();

  return (
    <DialogueTitle
      sx={{
        p: '28px',
        pb: '12px',
        '& > div': {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }
      }}
    >
      <Stack
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography variant={'H18'}>
          {t('shining3D:select_institution.dialog_title_from_shining_3d')}
        </Typography>

        <Stack
          sx={{
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center'
          }}
        >
          <Typography variant={'Body16'}>{userInfo.email}</Typography>
          <Button
            size={'36'}
            color={'grey'}
            variant={'outlined'}
            onClick={() => logoutShining3D()}
          >
            <Typography variant={'Button_Bold16'}>
              {t('shining3D:select_institution.btn_log_out')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </DialogueTitle>
  );
};
