import { TUserFactory } from '../../Shared/Types/factory';
import { Stack } from '@mui/material';
import { theme, Typography } from '@imago-cloud/design-system';
import { useSelectedInstitutionState } from '../../Shared/Atoms/selectedInstitution/useSelectedInstitutionState';

const ORG_TYPE = {
  lab: 'Laboratories',
  hospital: 'Hospital / Clinic'
};

export const InstitutionListItem = ({
  institution
}: {
  institution: TUserFactory;
}) => {
  const { selectInstitution } = useSelectedInstitutionState();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '20px',
        minHeight: '60px',
        height: '60px',
        pl: '20px',
        pr: '32px',
        borderRadius: '8px',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.action.hover
        },
        '&:active': {
          backgroundColor: 'grey.200'
        }
      }}
      onClick={() => selectInstitution(institution)}
    >
      <Typography
        variant={'Body16'}
        sx={{
          width: '240px'
        }}
      >
        {ORG_TYPE[institution.orgType] ?? ''}
      </Typography>
      <Typography variant={'Body16'}>{institution.name}</Typography>
    </Stack>
  );
};
