import { Button, Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const FileTransferring = ({
  onCancelClick
}: {
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.88)',
        zIndex: '1'
      }}
      justifyContent="center"
      alignItems="center"
      gap="40px"
    >
      <Loading
        label="Transfering data from Shining 3D"
        type="basic"
        size="44"
      />
      <Button
        variant="outlined"
        color="grey"
        size="36"
        sx={{ width: 'fit-content' }}
        onClick={onCancelClick}
      >
        {t('shining3D:banner_alert.btn_cancel')}
      </Button>
    </Stack>
  );
};
