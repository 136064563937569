import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useSystemSetting } from '@Shared/Apis/setting';
import { useTranslation } from 'react-i18next';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const useListDateFormat = () => {
  const { data } = useSystemSetting();
  const defaultDateForm = data?.data?.dateFormat ?? 'YYYY-MM-DD';
  const { t } = useTranslation();

  const getFormattedDate = (date: string) => {
    const dayjsDate = dayjs(date);

    if (dayjsDate.isToday()) {
      return `${t('explorer:ui_component.modified_date_today', { date: dayjsDate.format('HH:mm A') })}`;
    } else if (dayjsDate.isYesterday()) {
      return `${t('explorer:ui_component.modified_date_yesterday', { date: dayjsDate.format('HH:mm A') })}`;
    }
    return dayjsDate.format(`${defaultDateForm} HH:mm A`);
  };

  return { getFormattedDate };
};
