import { useEffect, useRef, useState } from 'react';

export const useGetOverflow = (): {
  y: boolean;
  x: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
} => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [overflowed, setIsOverflowed] = useState<{
    x: boolean;
    y: boolean;
  }>({
    x: false,
    y: false
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const checkOverflow = () => {
      const isOverflowedX = container.scrollWidth > container.clientWidth;
      const isOverflowedY = container.scrollHeight > container.clientHeight;
      setIsOverflowed({ x: isOverflowedX, y: isOverflowedY });
    };

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });
    resizeObserver.observe(container);

    checkOverflow();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { ...overflowed, containerRef };
};
