import { Stack } from '@mui/material';
import { CSSProperties, ElementType } from 'react';
import { useGetOverflow } from '@Shared/Hooks/useGetOverflow';

export const ScrollArea = ({
  sx,
  component,
  children
}: {
  sx?: CSSProperties;
  component?: ElementType;
  children: React.ReactNode;
}) => {
  const { y, containerRef } = useGetOverflow();

  return (
    <Stack sx={{ ...sx }} ref={containerRef}>
      <Stack
        sx={{ width: `calc(100% + ${y ? '14px' : '0px'})` }}
        component={component || 'div'}
      >
        {children}
      </Stack>
    </Stack>
  );
};
