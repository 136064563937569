import { OrderCaseListItem } from '@Features/Shining3D/Shining3DImporterContent/Components/OrderCaseListItem';
import { useFilteredOrderCaseList } from '@Features/Shining3D/Shining3DImporterContent/Hooks/useFilteredOrderCaseList';
import React, { useRef } from 'react';
import { useIntersectionObserver } from '@Shared/Hooks/useIntersectionObserver';
import { Box } from '@mui/material';
import { Loading } from '@imago-cloud/design-system';
import { NoOrderCaseList } from '@Features/Shining3D/Shining3DImporterContent/Components/NoOrderCaseList';

export const OrderCaseList = () => {
  const {
    filteredOrderCaseList,
    isFetching,
    hasNextPage,
    isLoading,
    fetchNextPage
  } = useFilteredOrderCaseList();
  const loadingRef = useRef(null);

  useIntersectionObserver(
    {
      target: loadingRef,
      enabled: hasNextPage,
      isFetching,
      threshold: 0
    },
    () => fetchNextPage()
  );
  return (
    <>
      {filteredOrderCaseList.length === 0 ? (
        <NoOrderCaseList />
      ) : (
        <>
          {filteredOrderCaseList.map((orderCase) => (
            <OrderCaseListItem key={orderCase.id} orderCase={orderCase} />
          ))}
          <Box ref={loadingRef}>
            {isFetching && !isLoading && (
              <Box>
                <Loading type="basic" size="24" />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};
