import { patchMeditInfo } from '../Apis/meditLinkAuthApis';
import { useMeditUserInfoState } from '../../Shared/Atoms/meditUserInfo/useMeditUserInfoState';

export const useMeditLinkLogout = () => {
  const { setMeditUserInfo } = useMeditUserInfoState();
  const logoutMeditLink = async () => {
    const requestData = {
      medit_accessToken: null,
      medit_refreshToken: null,
      medit_isAuthCompleted: false
    };

    await patchMeditInfo(requestData);
    setMeditUserInfo({ email: null });
  };

  return {
    logoutMeditLink
  };
};
