import { AxiosRequestConfig } from 'axios';
import {
  AUTH_CONNECTION_URL,
  AUTH_ENCRYPTED_TOKEN_FETCH_URL,
  AUTH_REFRESH_TOKEN_FETCH_URL,
  AUTH_TOKEN_FETCH_URL
} from '../Constants/apiUrls';
import {
  AUTH_CSRF_COOKIE,
  CONNECTION_TOKEN_COOKIE
} from '../../Shared/Constants/cookies';
import Cookies from 'js-cookie';
import { shining3DAxiosInstance } from '../../Shared/Apis/axiosInstance';

const AUTH_RETRY_ERROR = ['csrfTokenErr', 'authErr'];
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

shining3DAxiosInstance.interceptors.response.use(
  async function (response) {
    if (response.data.status === 'fail') {
      if (AUTH_RETRY_ERROR.includes(response.data.result)) {
        const originalRequest = response.config as CustomAxiosRequestConfig;

        if (!originalRequest._retry) {
          originalRequest._retry = true;
          // 새 CSRF 토큰을 가져오는 로직
          const { result: newCsrfToken } = await getEncryptedToken();
          const { result: newConnectionToken } =
            await connectShining3DAuth(newCsrfToken);

          Cookies.set(AUTH_CSRF_COOKIE, newCsrfToken, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            secure: true
          });
          Cookies.set(CONNECTION_TOKEN_COOKIE, newConnectionToken, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            secure: true
          });

          // 원본 요청에 새 토큰 설정
          if (originalRequest.headers)
            originalRequest.headers['X-CSRF-Token'] = newCsrfToken;

          // 수정된 원본 요청으로 재시도
          return shining3DAxiosInstance(originalRequest);
        }
      } else {
        return Promise.reject(response?.data?.result?.id);
      }
    }

    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export const getEncryptedToken = async () => {
  const res = await shining3DAxiosInstance.get(AUTH_ENCRYPTED_TOKEN_FETCH_URL);
  return res.data;
};

export const connectShining3DAuth = async (csrf: string) => {
  const res = await shining3DAxiosInstance.post(
    AUTH_CONNECTION_URL,
    { publicKey: process.env.REACT_APP_SHINING3D_PUBLIC_KEY },
    {
      headers: {
        'X-Auth-CSRF': csrf ?? '',
        'X-Auth-AppKey': process.env.REACT_APP_SHINING3D_APP_KEY ?? ''
      }
    }
  );
  return res.data;
};

export const getUserInfoAndTokenUsingRefreshToken = async (
  refreshToken: string
) => {
  const res = await shining3DAxiosInstance.post(AUTH_REFRESH_TOKEN_FETCH_URL, {
    grantType: 'refreshToken',
    refreshToken
  });

  return res.data;
};

export const getUserInfoAndTokenUsingCode = (code: string) => {
  return shining3DAxiosInstance
    .post(AUTH_TOKEN_FETCH_URL, {
      redirectUri: process.env.REACT_APP_SHINING3D_REDIRECT_URL,
      responseType: 'token',
      grantType: 'authorizationCode',
      codeVerifier: 'PLAIN',
      code
    })
    .then((res) => {
      return res.data;
    });
};
