export const setIntervalWithTimeout = (
  callback: () => Promise<boolean>,
  timeout: number,
  duration: number,
  onTimeout: () => void
) => {
  const intervalStartedAt = new Date();

  const interval = setInterval(async () => {
    try {
      const isIntervalEnd = await callback();
      if (isIntervalEnd) {
        clearInterval(interval);
      }
    } catch (err) {
      clearInterval(interval);
    }

    const elapsedTime = +new Date() - +intervalStartedAt;

    if (elapsedTime > duration) {
      onTimeout();
      clearInterval(interval);
    }
  }, timeout);
  return interval;
};
