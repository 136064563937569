import { AppLoading } from '../AppLoading';
import i18n from '../../../i18n';
import { useEffect } from 'react';
import { useSystemSetting } from '@Shared/Apis/setting';
import { UnknownErrorDialogue } from '@Shared/Components/UnknownErrorDialogue';

export const AuthLoader = ({
  children
}: {
  children: React.ReactNode | null;
}) => {
  const { isSuccess, isLoading, data } = useSystemSetting();
  // 유저정보 필요하면 저장
  useEffect(() => {
    i18n.changeLanguage(data?.data.language.toLowerCase() ?? 'en');
  }, [data]);

  if (isLoading) return <AppLoading />;
  else if (!isSuccess) return <UnknownErrorDialogue />;
  else return <>{children}</>;
};
