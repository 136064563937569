import { ComponentType } from 'react';
import { useMeditLinkLogout } from '../Hooks/useMeditLinkLogout';

type TWithLogoutProps = {
  logoutMeditLink: () => Promise<void>;
};

export const withMeditLinkLogout = <P extends TWithLogoutProps>(
  Component: ComponentType<P>
) => {
  return function ComponentWithMeditLinkLogout(
    props: Omit<P, keyof TWithLogoutProps>
  ) {
    const { logoutMeditLink } = useMeditLinkLogout();

    return <Component {...(props as P)} logoutMeditLink={logoutMeditLink} />;
  };
};
