import { AUTH_REFRESH_TOKEN_API } from '../Constants/apiUrl';
import { unAuthorizedInstance } from './axiosInstance';

export const requestAccessToken = async () => {
  let accessToken_ = null;
  try {
    const response = await unAuthorizedInstance.patch(AUTH_REFRESH_TOKEN_API);
    const { data } = response.data;
    const { accessToken } = data;
    if (typeof accessToken !== 'string') {
      console.error('accessToken is not string');
      return null;
    }
    accessToken_ = accessToken;
  } catch (err) {
    // Refresh Token 만료
    console.error(err, 'refresh token expired');
  }
  return accessToken_;
};
