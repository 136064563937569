import { Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const LoadingFallback = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Loading size="44" type="basic" />
    </Stack>
  );
};
