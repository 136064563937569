import { useMeditLinkLogin } from './useMeditLinkLogin';
import { useEffect } from 'react';
import { useMeditUserInfoState } from '../../Shared/Atoms/meditUserInfo/useMeditUserInfoState';
import { useCallOnceAsync } from '@Shared/Hooks/useCallOnce';

export const useMeditLinkAuthInitializer = () => {
  const { isLoginProcessing, cancelLoginProcess, loginToMeditLink } =
    useMeditLinkLogin();

  const loginToMeditLinkOnce = useCallOnceAsync(loginToMeditLink);

  const { meditUserInfo } = useMeditUserInfoState();

  useEffect(() => {
    if (!meditUserInfo.email) {
      loginToMeditLinkOnce();
    }
  }, [meditUserInfo]);

  return {
    isLoginProcessing,
    cancelLoginProcess
  };
};
