import {
  MESH_DATA_EXTENSION_LIST,
  PRIORITY_IMPORT_FORMAT_TYPE,
  TEXTURE_DATA_EXTENSION_LIST
} from '../Constants/file';

export const filter3dModelFile = (files: File[]) => {
  return files.filter((file) => {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return (
      extension &&
      (MESH_DATA_EXTENSION_LIST.includes(extension) ||
        TEXTURE_DATA_EXTENSION_LIST.includes(extension))
    );
  });
};
export const findPriorityAttachType = (
  attachs:
    | {
        attachType: string;
        createOn: string;
        fileName: string;
        id: string;
      }[]
    | null
) => {
  for (const type of PRIORITY_IMPORT_FORMAT_TYPE) {
    const findAttachData = attachs?.find((item) => item.attachType === type);
    if (findAttachData) {
      return findAttachData.attachType;
    }
  }
  return undefined;
};
