import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getShining3dOrderList,
  getThirdPartyFactories
} from './shining3DListApis';
import {
  SHINING3D_ORDER_LIST,
  THIRD_PARTY_FACTORIES_KEY
} from '../../Shared/Constants/reactQueryKeys';
import { useShining3DCookies } from '@Features/Shining3D/Shared/Hooks/useShining3DCookies';
import { useSelectedInstitutionState } from '@Features/Shining3D/Shared/Atoms/selectedInstitution/useSelectedInstitutionState';
import { useDateFilterState } from '@Features/Shining3D/Shared/Atoms/dateFilter/useDateFilterState';

const useThirdPartyFactories = (token: string) => {
  return useQuery(
    [THIRD_PARTY_FACTORIES_KEY],
    () => getThirdPartyFactories(token),
    {
      retry: false,
      refetchOnWindowFocus: false,
      suspense: true
    }
  );
};

const useOrderListInfinite = () => {
  const { connectionToken } = useShining3DCookies();
  const { selectedInstitution } = useSelectedInstitutionState();
  const { dateFilter } = useDateFilterState();
  return useInfiniteQuery(
    [SHINING3D_ORDER_LIST, selectedInstitution, dateFilter],
    ({ pageParam = 1 }) =>
      getShining3dOrderList({
        date: dateFilter,
        orgCode: selectedInstitution?.orgCode ?? '',
        orgType: selectedInstitution?.orgType ?? '',
        token: connectionToken,
        pageParam
      }),
    {
      getNextPageParam: (data) => {
        let nextPage = undefined;
        if (
          data &&
          data.pageInfo.page !== data.pageInfo.pages &&
          data.pageInfo.total !== 0
        ) {
          nextPage = data.pageInfo.page + 1;
        }
        return nextPage;
      },
      retry: false,
      enabled: !!selectedInstitution?.orgCode,
      refetchOnWindowFocus: false,
      suspense: true
    }
  );
};

export { useThirdPartyFactories, useOrderListInfinite };
