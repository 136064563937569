import { useEffect, useRef, useState } from 'react';

interface IntersectionObserverProps {
  root?: React.RefObject<HTMLElement>;
  target: React.RefObject<HTMLElement>;
  isFetching: boolean;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}

export const useIntersectionObserver = (
  {
    root,
    target,
    threshold = 0.9,
    rootMargin = '0px',
    enabled = true,
    isFetching
  }: IntersectionObserverProps,
  callback: () => void
) => {
  useEffect(() => {
    if (!enabled || isFetching) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && callback()),
      {
        root: root && root.current,
        rootMargin,
        threshold
      }
    );

    const el = target?.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  });
};

interface IObserverOption {
  root?: Element;
  threshold?: number;
  rootMargin?: string;
}

export const useIntersectionObserverWithRef = (
  callback: () => Promise<unknown>,
  option?: IObserverOption
) => {
  const target = useRef<Element | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const io = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsLoading(true);
            callback().then(() => setIsLoading(false));
          }
        });
      },
      option ?? { root: null, rootMargin: '0px', threshold: 0.9 }
    );

    if (!target.current) return;

    io.observe(target.current);
    const cleanupTarget = target.current;

    return () => {
      if (cleanupTarget) {
        io.unobserve(cleanupTarget);
      }
    };
  }, [target, callback]);

  return { target, isLoading };
};
