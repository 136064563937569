import { InstitutionListItem } from './InstitutionListItem';
import { useInstitutionList } from '../Hooks/useInstitutionList';
import { NoInstitutionData } from './NoInstitutionData';

export const InstitutionList = () => {
  const { institutions } = useInstitutionList();
  return (
    <>
      {institutions.length === 0 ? (
        <NoInstitutionData />
      ) : (
        <>
          {institutions?.map((institution) => (
            <InstitutionListItem
              key={institution.id}
              institution={institution}
            />
          ))}
        </>
      )}
    </>
  );
};
