import { useMeditLinkRefreshAccessToken } from './useMeditLinkRefreshAccessToken';
import { useMeditSelectedFileListState } from '../../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';
import {
  useMeditCaseList,
  useMeditFileListInfo
} from '../../Shared/Apis/meditLinkQueries';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

export const useSessionExpiredDialogue = () => {
  const { refreshAccessToken } = useMeditLinkRefreshAccessToken();
  const { fileList } = useMeditSelectedFileListState();
  const {
    refetch,
    error: caseListerror,
    isError: isCaseListError
  } = useMeditCaseList();
  const { error: transferringError, isError: isTransferringError } =
    useMeditFileListInfo(fileList);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    if (
      (caseListerror as AxiosError)?.response?.status === 401 ||
      (transferringError as AxiosError)?.response?.status === 401
    ) {
      setIsSessionExpired(true);
    }
  }, [isTransferringError, isCaseListError]);

  const onClick = async () => {
    const isAccessTokenRefreshed = await refreshAccessToken();
    if (isAccessTokenRefreshed) {
      await refetch();
      setIsSessionExpired(false);
    }
  };

  return {
    isSessionExpired,
    onClick
  };
};
