import { useMeditLinkAuthInitializer } from '../Hooks/useMeditLinkAuthInitializer';
import { AuthorizationBanner } from './AuthorizationBanner';
import { ReactNode } from 'react';
import { useMeditUserInfoState } from '../../Shared/Atoms/meditUserInfo/useMeditUserInfoState';

export const MeditLinkAuth = ({ children }: { children: ReactNode }) => {
  const { isLoginProcessing, cancelLoginProcess } =
    useMeditLinkAuthInitializer();
  const { meditUserInfo } = useMeditUserInfoState();

  return (
    <>
      {isLoginProcessing && (
        <AuthorizationBanner onCancelClick={cancelLoginProcess} />
      )}
      {meditUserInfo.email && <>{children}</>}
    </>
  );
};
