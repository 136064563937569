import { Stack } from '@mui/material';
import { WarningIcon } from '@Shared/Components/WarningIcon/WarningIcon';
import { Typography } from '@imago-cloud/design-system';
import { useTranslation } from 'react-i18next';

export const NoOrderCaseList = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: '12px'
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px'
        }}
      >
        <WarningIcon />
        <Typography variant={'H24'}>
          {t('shining3D:no_order.error_title_no_cases')}
        </Typography>
      </Stack>
      <Typography variant={'Body18'}>
        {t('shining3D:no_order.error_body_select_a_date')}
      </Typography>
    </Stack>
  );
};
