export const DATE_TYPE_NAME = {
  CREATED_TIME: 'createdAt' as const,
  MODIFIED_TIME: 'updatedAt' as const
};

export const DATE_TYPE_VALUE = {
  CREATED: 'created' as const,
  MODIFIED: 'modified' as const
};

export const DATE_TYPE = {
  [DATE_TYPE_NAME.CREATED_TIME]: {
    name: 'Created',
    value: DATE_TYPE_VALUE.CREATED,
    columnName: DATE_TYPE_NAME.CREATED_TIME,
    i18nKey: 'explorer:ui_component_list_hd.menu_created' as const
  },
  [DATE_TYPE_NAME.MODIFIED_TIME]: {
    name: 'Modified',
    value: DATE_TYPE_VALUE.MODIFIED,
    columnName: DATE_TYPE_NAME.MODIFIED_TIME,
    i18nKey: 'explorer:ui_component_list_hd.menu_modified' as const
  }
};
