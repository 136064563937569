// MEDIT
const MEDIT_GET_USER_INFO_API = 'v1/me';
const MEDIT_GET_CASE_LIST_API = 'v1/cases/search';
const MEDIT_GET_CASE_DETAIL_API = (uuid: string) => `/v1/cases/${uuid}`;
const MEDIT_GET_FILE_DETAIL_API = (uuid: string) =>
  `/v1/files/${uuid}?type=ply`;
const MEDIT_POST_MEDIT_LINK_AUTHENTICATE_DATA_API =
  'api/medit-link-authenticate-data';

export {
  MEDIT_GET_CASE_DETAIL_API,
  MEDIT_GET_FILE_DETAIL_API,
  MEDIT_GET_CASE_LIST_API,
  MEDIT_POST_MEDIT_LINK_AUTHENTICATE_DATA_API,
  MEDIT_GET_USER_INFO_API
};
