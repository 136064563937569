import React from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Ricon,
  TextField,
  Typography
} from '@imago-cloud/design-system';
import { Stack, styled } from '@mui/material';
import { useCaseListPageContext } from '../Context';
import { useListDateFilter } from './Hooks/useListDateFilter';
import { useListSearchFilter } from './Hooks/useListSearchFilter';
import { Controller } from 'react-hook-form';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { useCaseListSort } from './Hooks/useCaseListSort';
import { DATE_TYPE } from '../Shared/Constants/caseDateType';
import { DateRangePicker } from '@Shared/Components/DateRangePicker';
import { CaseSolutionFilter } from '@Features/Explorer/ExplorerHeader/Components';
import { useTranslation } from 'react-i18next';

export const ExplorerDialogueTitle = () => {
  const caseListPageContext = useCaseListPageContext();
  const caseListSort = useCaseListSort();
  const { handleDateChange } = useListDateFilter(caseListPageContext);
  const { control, clearField, onSubmit } =
    useListSearchFilter(caseListPageContext);

  const { t } = useTranslation();

  const DATE_TYPE_LIST = Object.values(DATE_TYPE);

  const {
    dateType,
    isDescending,
    handleChangeDateType,
    handleChangeOrder,
    isDateTypeMenuOpen,
    handleDateTypeButtonClick,
    handleCloseDateTypeMenu,
    anchorEl
  } = caseListSort;

  return (
    <>
      <Stack
        flexDirection="row"
        sx={{
          height: '76px',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '28px 28px 12px 28px'
        }}
      >
        <CaseSolutionFilter />
        <Stack flexDirection="row" gap="8px" sx={{ alignItems: 'center' }}>
          <DateRangePicker handleDateChange={handleDateChange} />
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              render={({ field: { ref, value, ...others } }) => (
                <TextField
                  size="36"
                  placeholder={t('explorer:ui_component.place_holder_search')}
                  sx={{ width: '200px' }}
                  inputRef={ref}
                  value={value}
                  {...others}
                  inputProps={{ maxLength: 200, 'data-cy': 'searchInput' }}
                  InputProps={{
                    endAdornment: value ? (
                      <IconButton
                        key={'close'}
                        size="28"
                        type="button"
                        variant="transparent"
                        onClick={() => {
                          clearField();
                          onSubmit();
                        }}
                        sx={{
                          m: '4px'
                        }}
                      >
                        <Ricon
                          icon="ri-close-fill"
                          svgProps={{
                            fill: grey[800]
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        key={'search'}
                        size="28"
                        onClick={onSubmit}
                        variant="transparent"
                        data-cy="submitSearch"
                        sx={{
                          m: '4px'
                        }}
                      >
                        <Ricon
                          icon="ri-search-line"
                          svgProps={{
                            fill: grey[800]
                          }}
                        />
                      </IconButton>
                    )
                  }}
                />
              )}
              name="search"
              defaultValue={''}
            />
          </form>
        </Stack>
      </Stack>
      <Stack
        flexDirection="row"
        sx={{
          height: '52px',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 34px 12px 52px',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Typography
          variant="Subtitle_Semibold14"
          color="grey.800"
          sx={{ width: '406px' }}
        >
          {t('explorer:ui_component_list_hd.case_name')}
        </Typography>
        <Typography
          variant="Subtitle_Semibold14"
          color="grey.800"
          sx={{ width: '240px' }}
        >
          {t('explorer:ui_component_list_hd.type')}
        </Typography>
        <Typography
          variant="Subtitle_Semibold14"
          color="grey.800"
          sx={{ width: '148px' }}
        >
          {t('explorer:ui_component_list_hd.status')}
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: '162px', height: '28px' }}
        >
          <SortingButton
            sx={{ ml: '-12px' }}
            variant="text"
            size="36"
            color="grey"
            endIcon={
              <Ricon
                icon="ri-arrow-down-s-fill"
                svgProps={{
                  fill: grey[800],
                  width: 18,
                  height: 18
                }}
              />
            }
            onClick={handleDateTypeButtonClick}
            data-cy="sortDateModeButton"
          >
            {t(dateType.i18nKey)}
          </SortingButton>
          <IconButton
            size="28"
            onClick={handleChangeOrder}
            variant="transparent"
            data-cy="sortOrderButton"
          >
            <Ricon
              icon={isDescending ? 'ri-arrow-down-line' : 'ri-arrow-up-line'}
              svgProps={{
                fill: grey[800]
              }}
            />
          </IconButton>
          <Menu
            PaperProps={{
              sx: { width: '109px', padding: '4px', borderRadius: '8px' }
            }}
            open={isDateTypeMenuOpen}
            onClose={handleCloseDateTypeMenu}
            anchorEl={anchorEl}
          >
            {DATE_TYPE_LIST.map((item) => (
              <MenuItem
                size={'34'}
                key={item.name}
                onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                  handleChangeDateType(e, item)
                }
                selected={dateType.name === item.name}
                data-cy={`dateMode-${item.name}`}
              >
                {t(item.i18nKey)}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Stack>
    </>
  );
};

export const SortingButton = styled(Button)`
  ${({ theme }) => theme.typography.Subtitle_Semibold14}
  color: ${({ theme }) => theme.palette.grey[800]};
  padding: 3px 4px 3px 12px;
  .MuiButton-endIcon {
    margin-left: 4px;
  }
`;
