import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { IconButton, Label, Ricon, Tooltip } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { join } from '@Shared/Utils/helpers';
import { useTranslation } from 'react-i18next';

export const TypeToothLabel = ({ labelList }: { labelList: string[] }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [isVisibleMoreButton, setIsVisibleMoreButton] = useState(false);

  const toolTipText = useMemo(() => join(' • ', labelList), [labelList]);
  const maxWidth = 212;
  const { t } = useTranslation();

  useLayoutEffect(() => {
    let totalWidth = 0;
    const newVisibleItems: string[] = [];

    itemRefs.current.forEach((ref, index) => {
      if (ref) {
        const itemWidth = ref.getBoundingClientRect().width;
        if (totalWidth + itemWidth <= maxWidth) {
          totalWidth += itemWidth;
          newVisibleItems.push(labelList[index]);
        } else if (!isVisibleMoreButton) {
          setIsVisibleMoreButton(true);
        }
      }
    });

    setVisibleItems(newVisibleItems);
  }, [labelList]);

  return (
    <Stack
      flexDirection="row"
      sx={{
        width: '240px',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Stack
        flexDirection="row"
        gap="4px"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: '212px', overflow: 'hidden' }}
      >
        {labelList.length > 0 ? (
          labelList.map((label, index) => {
            return (
              <TypeToothLabelBox
                title={label}
                refCallback={(el: HTMLDivElement | null) => {
                  itemRefs.current[index] = el;
                }}
                isVisible={visibleItems.includes(label)}
                key={index}
              />
            );
          })
        ) : (
          <TypeToothLabelBox title={t('explorer:ui_component_label.none')} />
        )}
      </Stack>
      {isVisibleMoreButton && (
        <Tooltip
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
          placement="bottom"
          title={toolTipText}
          sx={{ width: '297px' }}
        >
          <IconButton
            size="28"
            type="button"
            variant="transparent"
            color="grey900"
            icon_size="small"
            hover_color="black"
            onClick={(e) => {
              e.stopPropagation();
              setOpenTooltip((prev) => !prev);
            }}
          >
            <Ricon
              icon="ri-more-fill"
              svgProps={{
                fill: grey[900]
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

const TypeToothLabelBox = ({
  title,
  refCallback,
  isVisible = true
}: {
  title: string;
  refCallback?: any;
  isVisible?: boolean;
}) => {
  return (
    <Box
      ref={refCallback}
      sx={{
        width: 'fit-content !important',
        maxWidth: '100%',
        visibility: isVisible ? 'visible' : 'hidden'
      }}
    >
      <Label
        variant="outlined"
        data-cy="caseType"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: 'fit-content',
          maxWidth: '100%',
          display: 'block',
          py: '0px'
        }}
      >
        {title}
      </Label>
    </Box>
  );
};
