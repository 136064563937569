import { useRecoilState } from 'recoil';
import { caseStatusFilterState } from './atom';

export const useCaseStatusFilterState = () => {
  const [caseStatusFilter, setCaseStatusFilter] = useRecoilState(
    caseStatusFilterState
  );

  const toggleFilterOption = (filterOption: string) => {
    if (caseStatusFilter.includes(filterOption)) {
      setCaseStatusFilter((prev) =>
        prev.filter((status) => status !== filterOption)
      );
    } else {
      setCaseStatusFilter((prev) => [...prev, filterOption]);
    }
  };

  return {
    caseStatusFilter,
    setCaseStatusFilter,
    toggleFilterOption
  };
};
