import { Stack } from '@mui/material';
import { Cicon, Typography } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { useTranslation } from 'react-i18next';

export const NoCaseList = () => {
  const { t } = useTranslation();
  return (
    <Stack
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap="12px"
      sx={{
        width: '100%',
        height: '100%',
        marginTop: '245px',
        marginBottom: '249px'
      }}
    >
      <Cicon
        icon="NoResult"
        svgProps={{
          width: 64,
          height: 64,
          fill: grey[800]
        }}
      />
      <Stack
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        gap="8px"
      >
        <Typography variant="H24" color="text.secondary">
          {t('explorer:open_dialog.no_search_title')}
        </Typography>
        <Typography variant="Subtitle_Semibold16" color="text.secondary">
          {t('explorer:open_dialog.no_search_body')}
        </Typography>
      </Stack>
    </Stack>
  );
};
