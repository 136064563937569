import { TMeditUserWithToken } from '../../Shared/Types/meditUserType';
import { getUserInfo } from '@Shared/Apis/user';
import {
  meditAuthAxiosInstance,
  meditAxiosInstance
} from '../../Shared/Apis/meditAxiosInstance';
import {
  MEDIT_GET_USER_INFO_API,
  MEDIT_POST_MEDIT_LINK_AUTHENTICATE_DATA_API
} from '../../Shared/Constants/apiUrl';
import { axiosInstance } from '@Shared/Apis/axiosInstance';

const getMeditLinkAccessToken = async (refreshToken: string) => {
  const requestUrl = `oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

  const res = await meditAuthAxiosInstance.post(requestUrl);
  return res.data;
};

const getMeditUserInfo = async (accessToken: string) => {
  const res = await meditAxiosInstance.get(MEDIT_GET_USER_INFO_API, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return res.data;
};

const patchMeditInfo = async (data: {
  medit_accessToken?: null;
  medit_refreshToken?: null;
  medit_state?: string;
  medit_isAuthCompleted?: boolean;
}) => {
  const res = await axiosInstance.patch(
    MEDIT_POST_MEDIT_LINK_AUTHENTICATE_DATA_API,
    data
  );
  return res.data;
};

const getExistingMeditLinkAccessToken = async () => {
  const { user } = await getUserInfo();

  if (user.medit_isAuthCompleted) {
    return user.medit_accessToken as string;
  }
  return '';
};

const getMeditLinkAccessTokenUsingRefreshToken = async () => {
  const { user } = await getUserInfo();
  const res = await getMeditLinkAccessToken(user.medit_refreshToken);

  return res.access_token;
};

const getMeditUserInfoWithToken = async (
  accessToken: string
): Promise<TMeditUserWithToken> => {
  const res = await getMeditUserInfo(accessToken);
  const groupUUID = res.group.uuid;
  return { accessToken, groupUUID, userInfo: res };
};

export {
  getMeditUserInfoWithToken,
  getMeditLinkAccessTokenUsingRefreshToken,
  getExistingMeditLinkAccessToken,
  patchMeditInfo
};
