import { useInfiniteQuery } from '@tanstack/react-query';
import { axiosInstance } from '@Shared/Apis/axiosInstance';
import { IQueryOption } from '../../Shared/Types/listContext';
import { TDesignCase } from '../../Shared/Types/designCase';
import { DESIGN_CASE_LIST_API } from '../Constants';
import { DESIGN_CASE_LIST_KEY } from '../../Shared/Constants/reactQueryKey';

export const getDesignCaseList = ({
  queryOption,
  pageOption
}: {
  queryOption: IQueryOption;
  pageOption: { offset: number };
}) => {
  const queryParam = getCaseListQueryOption(queryOption);
  const pageParam = pageOption ? `offset=${pageOption.offset}` : `offset=1`;

  return axiosInstance
    .get(`${DESIGN_CASE_LIST_API}?${queryParam}&${pageParam}`)
    .then((res) => res.data);
};

export const useDesignCaseListInfiniteQuery = (queryOption: IQueryOption) => {
  return useInfiniteQuery<{
    success: boolean;
    data: TDesignCase[];
    page: { offset: number; totalPages: number };
  }>({
    queryKey: [DESIGN_CASE_LIST_KEY, queryOption],
    queryFn: (pageParam) => {
      const nextPageParam = pageParam.pageParam;
      return getDesignCaseList({ queryOption, pageOption: nextPageParam });
    },
    getNextPageParam: (lastPage) => {
      const hasNext = lastPage?.page?.offset < lastPage?.page?.totalPages;
      return hasNext ? { offset: lastPage?.page?.offset + 1 } : undefined;
    },
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: false
  });
};

export const getCaseListQueryOption = (option: IQueryOption) => {
  const { date, search, limit, sort, dateType, solution, platform } =
    option ?? {};
  let queryOption = '';

  if (date && !Object.values(date).some((item) => item === null)) {
    queryOption = queryOption.concat(getDateQueryOption(date));
  }

  if (search) {
    queryOption = queryOption.concat(`&name=${search}`);
  }

  if (sort || dateType) {
    const sortQuerySign = sort ? (sort.isDescending ? 'desc' : 'asc') : 'desc';
    queryOption = queryOption.concat(`&sort=${dateType}:${sortQuerySign}`);
  }

  if (solution && solution !== 'ALL') {
    queryOption = queryOption.concat(`&solution=${solution}`);
  }

  if (limit) {
    queryOption = queryOption.concat(`&limit=${limit}`);
  }

  if (platform) {
    queryOption = queryOption.concat(`&platform=${platform}`);
  }

  queryOption =
    queryOption.charAt(0) === '&' ? queryOption.substring(1) : queryOption;

  return queryOption;
};

const getDateQueryOption = (date: Required<IQueryOption['date']>) => {
  const formatType = 'YYYY-MM-DD';
  const startDate = date?.startDate?.format(formatType);
  const endDate = date?.endDate?.format(formatType);

  return `&startDate=${startDate}&endDate=${endDate}`;
};
