import { useState } from 'react';
import { DATE_TYPE } from '../../Shared/Constants/caseDateType';
import { useCaseListPageContext } from '../../Context';
import { ValueOf } from '../../Shared/Types/utils';

export type UseCaseListSort = ReturnType<typeof useCaseListSort>;

export const useCaseListSort = () => {
  const { queryOption, setQueryOption } = useCaseListPageContext();
  const [isDateTypeMenuOpen, setIsDateTypeMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const dateType = DATE_TYPE[queryOption.dateType];
  const isDescending = queryOption.sort.isDescending;

  const handleChangeDateType = (
    _: React.MouseEvent<HTMLElement>,
    value: ValueOf<typeof DATE_TYPE>
  ) => {
    const newValue = value.columnName;
    setQueryOption((prev) => ({
      ...prev,
      dateType: newValue,
      sort: { target: newValue, isDescending: true }
    }));
    handleCloseDateTypeMenu();
  };

  const handleChangeOrder = () => {
    setQueryOption((prev) => ({
      ...prev,
      sort: { target: prev.sort.target, isDescending: !prev.sort.isDescending }
    }));
  };

  const handleDateTypeButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsDateTypeMenuOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateTypeMenu = () => {
    setIsDateTypeMenuOpen(false);
    setAnchorEl(null);
  };

  return {
    dateType,
    isDescending,
    handleChangeDateType,
    handleChangeOrder,
    isDateTypeMenuOpen,
    handleDateTypeButtonClick,
    handleCloseDateTypeMenu,
    anchorEl
  };
};
