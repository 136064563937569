import { useMeditCaseList } from '../../Shared/Apis/meditLinkQueries';
import { MeditCaseListItem } from './MeditCaseListItem';
import React, { useMemo, useRef } from 'react';
import { NoCaseListContent } from './NoCaseListContent';
import { Loading } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { useIntersectionObserver } from '@Shared/Hooks/useIntersectionObserver';

export const MeditCaseList = () => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useMeditCaseList();
  const list = useMemo(
    () => data?.pages.map((page) => page.content).flat(),
    [data]
  );

  const loadingRef = useRef(null);

  useIntersectionObserver(
    {
      target: loadingRef,
      enabled: hasNextPage,
      isFetching,
      threshold: 0
    },
    () => fetchNextPage()
  );

  if (isLoading)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Loading size="44" type="basic" />
      </Stack>
    );

  return (
    <>
      {list?.length ? (
        <>
          {list?.map((caseInfo) => (
            <MeditCaseListItem key={caseInfo.uuid} {...caseInfo} />
          ))}
          <Box ref={loadingRef}>
            {isFetching && (
              <Box>
                <Loading type="basic" size="24" />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <NoCaseListContent />
      )}
    </>
  );
};
