import { atom } from 'recoil';
import dayjs from 'dayjs';
import { TDate } from '@Shared/Types/dateType';

export const defaultDateFilter = {
  startDate: dayjs().subtract(29, 'days').startOf('day'),
  endDate: dayjs().endOf('day')
};

export const dateFilterState = atom<TDate>({
  key: 'dateFilter',
  default: defaultDateFilter
});
