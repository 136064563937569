import * as CryptoJS from 'crypto-js';

export const getEncryptData = async (
  bodyData: {
    orgCode: string;
    id: string;
    attachType: string;
  },
  csrfToken: string
) => {
  const csrfKey = CryptoJS.MD5(csrfToken).toString();

  //encryption request body
  const key = CryptoJS.enc.Utf8.parse(csrfKey);
  const iv = CryptoJS.enc.Utf8.parse(csrfKey.slice(0.16));
  const src = CryptoJS.enc.Utf8.parse(JSON.stringify(bodyData));
  const encrypted = CryptoJS.AES.encrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const encryptedBody = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedBody;
};
