import { useRecoilState } from 'recoil';
import { errorState } from './atom';
import { ERROR_TYPE } from '../../Constants/errorType';

export const useErrorState = () => {
  const [error, setError] = useRecoilState(errorState);

  const onUnknownError = () => {
    setError({ isError: true, errorType: ERROR_TYPE.UNKNOWN });
  };

  return {
    error,
    onUnknownError
  };
};
