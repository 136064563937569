import { useShining3DUserInfoState } from '../../Shared/Atoms/shining3DUserInfo/useShining3DUserInfoState';
import { useShining3DCookies } from '../../Shared/Hooks/useShining3DCookies';
import { useThirdPartyFactories } from '../Apis/queries';
import { TThirdPartyFactory, TUserFactory } from '../../Shared/Types/factory';

export const useInstitutionList = () => {
  const { connectionToken } = useShining3DCookies();
  const {
    userInfo: { factories }
  } = useShining3DUserInfoState();

  const { data, isFetching } = useThirdPartyFactories(connectionToken);

  const institutions = getUserAffiliatedFactoryList(
    factories,
    data.status === 'success' ? data?.result : []
  );

  return { institutions, isFetching };
};

const getUserAffiliatedFactoryList = (
  userFactoryList: TUserFactory[],
  thirdPartyFactoryList: TThirdPartyFactory[]
) => {
  const thirdPartyFactoryIdList = thirdPartyFactoryList?.map(
    (el) => el.factoryID
  );
  return userFactoryList?.filter((x) =>
    thirdPartyFactoryIdList?.includes(x.id)
  );
};
