import { useMeditLinkLogout } from './useMeditLinkLogout';
import { setMeditAxiosInstanceHeader } from '../../Shared/Apis/meditAxiosInstance';
import { getMeditLinkAccessTokenUsingRefreshToken } from '../Apis/meditLinkAuthApis';

export const useMeditLinkRefreshAccessToken = () => {
  const { logoutMeditLink } = useMeditLinkLogout();

  const refreshAccessToken = async () => {
    try {
      setMeditAxiosInstanceHeader({
        accessToken: await getMeditLinkAccessTokenUsingRefreshToken()
      });
      return true;
    } catch (err) {
      await logoutMeditLink();
    }
    return false;
  };

  return {
    refreshAccessToken
  };
};
