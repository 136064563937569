export const ADANumber = {
  8: '8',
  7: '7',
  6: '6',
  5: '5',
  4: '4',
  3: '3',
  2: '2',
  1: '1',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: '30',
  31: '31',
  32: '32',
  24: '24',
  23: '23',
  22: '22',
  21: '21',
  20: '20',
  19: '19',
  18: '18',
  17: '17',

  // baby teeth numbering
  E: 'E',
  D: 'D',
  C: 'C',
  B: 'B',
  A: 'A',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  O: 'O',
  N: 'N',
  M: 'M',
  L: 'L',
  K: 'K'
} as const;
export type ADANumber = (typeof ADANumber)[keyof typeof ADANumber];

export const FDINumber = {
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  41: '41',
  42: '42',
  43: '43',
  44: '44',
  45: '45',
  46: '46',
  47: '47',
  48: '48',
  31: '31',
  32: '32',
  33: '33',
  34: '34',
  35: '35',
  36: '36',
  37: '37',
  38: '38',

  // baby teeth numbering
  51: '51',
  52: '52',
  53: '53',
  54: '54',
  55: '55',
  61: '61',
  62: '62',
  63: '63',
  64: '64',
  65: '65',
  81: '81',
  82: '82',
  83: '83',
  84: '84',
  85: '85',
  71: '71',
  72: '72',
  73: '73',
  74: '74',
  75: '75'
} as const;
export type FDINumber = (typeof FDINumber)[keyof typeof FDINumber];

const _toADAHashTable: Record<FDINumber, ADANumber> = {
  '11': '8',
  '12': '7',
  '13': '6',
  '14': '5',
  '15': '4',
  '16': '3',
  '17': '2',
  '18': '1',
  '21': '9',
  '22': '10',
  '23': '11',
  '24': '12',
  '25': '13',
  '26': '14',
  '27': '15',
  '28': '16',
  '41': '25',
  '42': '26',
  '43': '27',
  '44': '28',
  '45': '29',
  '46': '30',
  '47': '31',
  '48': '32',
  '31': '24',
  '32': '23',
  '33': '22',
  '34': '21',
  '35': '20',
  '36': '19',
  '37': '18',
  '38': '17',

  // baby teeth numbering
  '51': 'E',
  '52': 'D',
  '53': 'C',
  '54': 'B',
  '55': 'A',
  '61': 'F',
  '62': 'G',
  '63': 'H',
  '64': 'I',
  '65': 'J',
  '81': 'P',
  '82': 'Q',
  '83': 'R',
  '84': 'S',
  '85': 'T',
  '71': 'O',
  '72': 'N',
  '73': 'M',
  '74': 'L',
  '75': 'K'
} as const;

export function toADANumber(inputFDI: FDINumber): ADANumber {
  return _toADAHashTable[inputFDI];
}
