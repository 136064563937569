import { atom } from 'recoil';
import { TUserFactory } from '../../Types/factory';

const defaultValue: { email: string; factories: TUserFactory[] } = {
  email: '',
  factories: []
};

export const shining3DUserInfoState = atom<typeof defaultValue>({
  key: 'shining3DUserInfo',
  default: defaultValue
});
