import {
  MeditLinkAuth,
  withMeditLinkLogout,
  withSessionExpiredDialogue
} from '@Features/MeditLink/Authenticate';
import { MeditLinkImporterHeader } from '@Features/MeditLink/MeditLinkImporterHeader';
import { Dialogue } from '@imago-cloud/design-system';
import { MeditLinkImporterContent } from '@Features/MeditLink/MeditLinkImporterContent';
import { MeditLinkImporterActions } from '@Features/MeditLink/MeditLinkImporterActions';

const MeditLinkHeaderWithLogout = withMeditLinkLogout(MeditLinkImporterHeader);

const MeditLinkImporterDialogue = () => {
  return (
    <Dialogue
      id="import_module_dialogue"
      open
      PaperProps={{
        sx: {
          width: '1120px !important',
          minWidth: '1120px',
          height: '836px !important',
          maxHeight: '836px'
        }
      }}
    >
      <MeditLinkHeaderWithLogout />
      <MeditLinkImporterContent />
      <MeditLinkImporterActions />
    </Dialogue>
  );
};

const ImporterDialogueWithSessionExpiredDialogue = withSessionExpiredDialogue(
  MeditLinkImporterDialogue
);

export const MeditLinkImporterPage = () => {
  return (
    <MeditLinkAuth>
      <ImporterDialogueWithSessionExpiredDialogue />
    </MeditLinkAuth>
  );
};
