import { useRecoilState } from 'recoil';
import { selectedInstitutionState } from './atom';
import { TUserFactory } from '../../Types/factory';

export const useSelectedInstitutionState = () => {
  const [selectedInstitution, setSelectedInstitution] = useRecoilState(
    selectedInstitutionState
  );

  const selectInstitution = (selected: TUserFactory | null) => {
    setSelectedInstitution(selected);
  };

  const resetInstitution = () => {
    setSelectedInstitution(null);
  };

  return {
    selectedInstitution,
    selectInstitution,
    resetInstitution
  };
};
