import { useQueryClient } from '@tanstack/react-query';
import { Stack } from '@mui/material';
import { Button, Loading } from '@imago-cloud/design-system';
import { useMeditSelectedFileListState } from '../../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';
import { useTranslation } from 'react-i18next';

export const FileTransferring = () => {
  const { fileList } = useMeditSelectedFileListState();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onCancelClick = () => {
    queryClient.cancelQueries({ queryKey: ['meditFileListInfo', fileList] });
  };

  return (
    <Stack
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.88)',
        zIndex: '1'
      }}
      justifyContent="center"
      alignItems="center"
      gap="40px"
    >
      <Loading
        label={t('meditLink:transferring.loading_txt_transferring')}
        type="basic"
        size="44"
      />
      <Button
        variant="outlined"
        color="grey"
        size="36"
        sx={{ width: 'fit-content' }}
        onClick={onCancelClick}
      >
        {t('meditLink:banner_alert.btn_cancel')}
      </Button>
    </Stack>
  );
};
