import { normalizeUrl } from './normalize';
import { AccessEnv } from '@Src/config';
import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';

export const getReferrer = () => {
  const referrer = document.referrer;

  if (!referrer) {
    //electron referrer 포함하는지?
    const isElectronApp = navigator.userAgent.includes('Electron');
    if (isElectronApp) return 'file://';
    else {
      console.error('invalid origin');
      return '';
    }
  } else return normalizeUrl(referrer);
};

export const getReferrerCheck = () => {
  const referrer = getReferrer();
  if (AccessEnv(['local', 'dev'])) return true;
  else return ALLOW_ORIGIN_LIST.includes(referrer);
};
