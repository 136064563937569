import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import {
  IMPORT,
  MAIN,
  MEDIT_LINK,
  SHINING3D
} from '@Shared/Constants/routePath';
import { UnknownErrorDialogue } from '@Shared/Components/UnknownErrorDialogue';
import { ExplorerDialogue } from '@Pages/ExplorerDialogue';
import { AppLayout } from './AppLayout';
import { Shining3DImporterPage } from '@Pages/Shining3DImporter';
import { MeditLinkImporterPage } from '@Pages/MeditLinkImporter';

export const routePath: RouteObject[] = [
  {
    path: MAIN,
    element: <AppLayout />,
    errorElement: <></>,
    children: [
      {
        index: true,
        element: <ExplorerDialogue />
      },
      {
        path: IMPORT,
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <ExplorerDialogue />
          },
          {
            path: SHINING3D,
            element: <Shining3DImporterPage />
          },
          {
            path: MEDIT_LINK,
            element: <MeditLinkImporterPage />
          }
        ]
      },
      {
        path: '*',
        element: <UnknownErrorDialogue />
      }
    ]
  }
];

export default function Routers() {
  const routes = useRoutes(routePath);
  return routes;
}
