import { Box, Stack } from '@mui/material';
import { Loading, Ricon, Typography } from '@imago-cloud/design-system';
import { TDesignCase } from '../../../Shared/Types/designCase';
import { UseGetCaseList } from '../../Hooks/useGetCaseList';
import { IMPORT_PLATFORM } from '@Shared/Constants/importPlatform';
import CaseStatusLabel from './CaseStatusLabel';
import { useListDateFormat } from '../../Hooks/useListDateFormat';
import { BatchCaseLabel } from './BatchCaseLabel';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { UseCaseListSort } from '../../../ExplorerHeader/Hooks/useCaseListSort';
import { TypeToothLabel } from './TypeToothLabel';
import { useCaseListPageContext } from '../../../Context';
import { useProductLabel } from '@Features/Explorer/ExplorerContent/Hooks/useProductLabel';
import { useTranslation } from 'react-i18next';

interface CaseListItemProps {
  item: TDesignCase;
  dateType: UseCaseListSort['dateType'];
  isFetching: UseGetCaseList['isFetching'];
  nowDesignCaseId: TDesignCase['id'];
}

export const CaseListItem = ({
  item,
  dateType,
  isFetching,
  nowDesignCaseId
}: CaseListItemProps) => {
  const { selectedItem, setSelectedItem } = useCaseListPageContext();

  const handleSelectCaseItem = (item: TDesignCase) => {
    setSelectedItem(item);
  };
  const isBatchCase = item.importPlatform === IMPORT_PLATFORM.BATCH;
  const isSelected = selectedItem?.id === item.id;
  const isNowDesignCase = nowDesignCaseId === item.id;

  const { getFormattedDate } = useListDateFormat();

  const { labelList } = useProductLabel(item);
  const { t } = useTranslation();

  return (
    <Stack
      flexDirection="row"
      onClick={() => !isNowDesignCase && handleSelectCaseItem(item)}
      sx={{
        padding: '4px 20px 4px 12px',
        borderRadius: '4px',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: isNowDesignCase ? 'initial' : 'pointer',
        backgroundColor: isNowDesignCase
          ? 'action.disabledBackground'
          : isSelected
            ? 'blue.10'
            : 'common.white',
        ...(!isNowDesignCase && {
          '&:hover': {
            backgroundColor: 'action.hover'
          }
        })
      }}
    >
      <Stack
        flexDirection="row"
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        {isBatchCase && <BatchCaseLabel />}
        <Stack
          sx={{
            width: '90px',
            height: '60px',
            border: '1px solid',
            borderColor: isSelected ? 'blue.500' : 'grey.500',
            borderRadius: '8px',
            backgroundColor: 'common.white',
            mr: '16px',
            ml: isBatchCase ? '8px' : '26px',
            position: 'relative'
          }}
        >
          {isFetching ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Loading type="basic" size="10" />
            </Stack>
          ) : (
            <>
              {item.thumbnailUrl ? (
                <Box
                  component="img"
                  src={item.thumbnailUrl}
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                  crossOrigin={'anonymous'}
                />
              ) : (
                <Stack
                  sx={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    backgroundColor: 'grey.200'
                  }}
                >
                  <Ricon
                    icon="ri-image-2-fill"
                    svgProps={{
                      width: 40,
                      height: 40,
                      fill: grey[600]
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
        {isNowDesignCase ? (
          <Stack
            flexDirection="column"
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '300px'
            }}
          >
            <Typography variant="Subtitle_Semibold12" color="blue.500">
              {t('explorer:ui_component_list.work_in_progress')}
            </Typography>
            <Typography
              variant="Body14"
              textOverflow="ellipsis"
              noWrap
              whiteSpace="pre"
              sx={{ width: '100%' }}
              color="text.primary"
            >
              {item.name}
            </Typography>
          </Stack>
        ) : (
          <Typography
            variant={isSelected ? 'Subtitle_Semibold14' : 'Body14'}
            textOverflow="ellipsis"
            noWrap
            whiteSpace="pre"
            sx={{ width: '300px' }}
            color={isSelected ? 'primary.main' : 'text.primary'}
          >
            {item.name}
          </Typography>
        )}
      </Stack>

      <TypeToothLabel labelList={labelList} />

      <Stack sx={{ width: '148px' }}>
        <CaseStatusLabel
          key={item.status}
          label={item.status}
          data-cy="caseStatus"
        />
      </Stack>
      <Stack sx={{ width: '162px' }}>
        <Typography
          variant="Body14"
          sx={{ color: 'text.thirdary' }}
          data-cy="caseDate"
        >
          {getFormattedDate(item[dateType.columnName] ?? '')}
        </Typography>
      </Stack>
    </Stack>
  );
};
