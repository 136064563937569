import { v4 as uuidV4 } from 'uuid';
import { windowOpenCenter } from '@Shared/Utils/windowOpenCenter';
import { patchMeditInfo } from '../Apis/meditLinkAuthApis';

const getMeditLinkLoginUrl = (meditState: string) =>
  `${process.env.REACT_APP_MEDIT_LINK_LOGIN_URL}?client_id=${process.env.REACT_APP_X_MEDITLINK_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_MEDIT_REDIRECT_URL}&scope=USER GROUP CASE FILE ORDER&state=${meditState}`;

const openMeditLinkWindow = async () => {
  const meditState = uuidV4();

  const payload = {
    medit_state: meditState,
    medit_isAuthCompleted: false
  };

  await patchMeditInfo(payload);

  const url = getMeditLinkLoginUrl(meditState);

  return windowOpenCenter(url, 'medit', { width: 750, height: 900 });
};

export { openMeditLinkWindow };
