import { atom } from 'recoil';
import { ErrorType } from '../../Constants/errorType';

export type ErrorStateType = {
  isError: boolean;
  errorType?: ErrorType;
};

export const errorState = atom<ErrorStateType>({
  key: 'errorState',
  default: {
    isError: false
  }
});
