import {
  Button,
  DialogueActions,
  Typography
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useShining3DCookies } from '../Shared/Hooks/useShining3DCookies';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useSelectedOrderCaseState } from '../Shared/Atoms/selectedOrderCase/useSelectedOrderCaseState';
import { useShining3DFileTransfer } from './Hooks/useShining3DFileTransfer';
import { FileTransferring } from './Components/FileTransferring';
import { FileConversionFailedDialogue } from './Components/FileConversionFailedDialogue';
import { useTranslation } from 'react-i18next';

export const Shining3DImporterActions = () => {
  const { removeNonAuthCookies } = useShining3DCookies();
  const { selectedOrderCase } = useSelectedOrderCaseState();
  const {
    onImportClick,
    isTransferring,
    isConversionFailed,
    cancelTransferring,
    setIsConversionFailed
  } = useShining3DFileTransfer();

  const { t } = useTranslation();

  const onCancelClick = () => {
    removeNonAuthCookies();
    onCloseApp();
  };

  return (
    <>
      <DialogueActions>
        <Stack sx={{ flexDirection: 'row', gap: '10px' }}>
          <Button
            size={'36'}
            color={'grey'}
            variant={'outlined'}
            sx={{ width: '100px' }}
            onClick={onCancelClick}
          >
            <Typography variant={'Button_Bold16'}>
              {t('shining3D:banner_alert.btn_cancel')}
            </Typography>
          </Button>
          <Button
            size={'36'}
            color={'primary'}
            sx={{ width: '100px' }}
            disabled={!selectedOrderCase}
            onClick={onImportClick}
          >
            <Typography variant={'Button_Bold16'}>
              {t('shining3D:btn_stack.action_btn_import')}
            </Typography>
          </Button>
        </Stack>
      </DialogueActions>
      {isTransferring && (
        <FileTransferring onCancelClick={cancelTransferring} />
      )}
      {isConversionFailed && (
        <FileConversionFailedDialogue
          onCancelClick={() => setIsConversionFailed(false)}
        />
      )}
    </>
  );
};
