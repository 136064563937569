import { ILabel } from '@imago-cloud/design-system/lib/esm/Components/DataDisplay/Label/Label';
import { getCaseStatus } from '@Features/Shining3D/Shining3DImporterContent/Utils/getCaseStatus';
import { Label } from '@imago-cloud/design-system';

export const CaseStatusLabel = ({ status }: { status: string }) => {
  const { value, text } = getCaseStatus(status);

  const styles: { variant: ILabel['variant']; color: ILabel['color'] } =
    (() => {
      switch (value) {
        case 'Waiting to send':
          return { variant: 'ghost', color: 'default' };
        case 'Pending':
          return {
            variant: 'ghost',
            color: 'warning'
          };
        case 'Canceled':
          return { variant: 'ghost', color: 'default' };
        case 'Refused':
          return { variant: 'ghost', color: 'error' };
        case 'Waiting design':
          return { variant: 'ghost', color: 'primary' };
        case 'Design plan confirming':
          return { variant: 'ghost', color: 'primary' };
        case 'Design uploading':
          return { variant: 'ghost', color: 'primary' };
        case 'Design confirming':
          return { variant: 'ghost', color: 'primary' };
        case 'Producing':
          return { variant: 'ghost', color: 'primary' };
        case 'Shipping':
          return { variant: 'ghost', color: 'primary' };
        case 'Finished':
          return { variant: 'ghost', color: 'success' };
        default:
          return { variant: undefined, color: undefined };
      }
    })();

  return (
    <Label
      variant={styles.variant}
      color={styles.color}
      data-cy="caseStatusLabel"
    >
      {text}
    </Label>
  );
};
