import { useShining3DCookies } from '../../Shared/Hooks/useShining3DCookies';
import { useShining3DUserInfoState } from '../../Shared/Atoms/shining3DUserInfo/useShining3DUserInfoState';

export const useShining3DLogout = () => {
  const { removeAllCookie } = useShining3DCookies();
  const { setUserInfo } = useShining3DUserInfoState();

  const logoutShining3D = () => {
    removeAllCookie();
    setUserInfo({ email: '', factories: [] });
  };

  return { logoutShining3D };
};
