import { useRef, useState } from 'react';
import { openMeditLinkWindow } from '../Utils/openMeditLinkWindow';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useMeditUserInfoState } from '../../Shared/Atoms/meditUserInfo/useMeditUserInfoState';
import {
  getExistingMeditLinkAccessToken,
  getMeditLinkAccessTokenUsingRefreshToken,
  getMeditUserInfoWithToken
} from '../Apis/meditLinkAuthApis';
import { setIntervalWithTimeout } from '../Utils/setIntervalWithTimeout';

export const useMeditLinkLogin = () => {
  const [isLoginProcessing, setIsLoginProcessing] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const { setMeditUserInfoWithAxiosHeader } = useMeditUserInfoState();

  const resetLoginProcess = () => {
    setIsLoginProcessing(false);
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const cancelLoginProcess = () => {
    resetLoginProcess();
    onCloseApp();
  };

  const startLoginProcess = async () => {
    if (intervalId.current) return;

    setIsLoginProcessing(true);

    await openMeditLinkWindow();

    intervalId.current = setIntervalWithTimeout(
      async () => {
        const accessToken = await getExistingMeditLinkAccessToken();
        if (accessToken) {
          setMeditUserInfoWithAxiosHeader(
            await getMeditUserInfoWithToken(accessToken)
          );
          resetLoginProcess();
          return true;
        }
        return false;
      },
      4000,
      180000,
      cancelLoginProcess
    );
  };

  const loginToMeditLink = async () => {
    try {
      setMeditUserInfoWithAxiosHeader(
        await getMeditUserInfoWithToken(
          await getMeditLinkAccessTokenUsingRefreshToken()
        )
      );
      resetLoginProcess();
    } catch (err) {
      await startLoginProcess();
    }
  };

  return {
    isLoginProcessing,
    cancelLoginProcess,
    loginToMeditLink
  };
};
