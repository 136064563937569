import axios_default from 'axios';
import { BACKEND_BASE_URL_ACCOUNT } from '@Src/config';
import { createAxiosInstance } from './createAxiosInstance';

const Axios = {
  createAxiosInstance
};

export const unAuthorizedInstance = axios_default.create({
  baseURL: BACKEND_BASE_URL_ACCOUNT,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export const axiosInstance = Axios.createAxiosInstance();
