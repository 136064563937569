export const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M13.366 2.99996L22.892 19.5C23.0706 19.8094 23.0706 20.1905 22.892 20.4999C22.7134 20.8093 22.3832 20.9999 22.026 21H2.97397C2.61671 20.9999 2.28659 20.8093 2.10797 20.4999C1.92934 20.1905 1.92934 19.8094 2.10797 19.5L11.634 2.99996C11.8126 2.69058 12.1427 2.5 12.5 2.5C12.8572 2.5 13.1873 2.69058 13.366 2.99996Z"
      fill="#CC4646"
    />
    <path d="M11.1666 16V18.6667H13.8333V16H11.1666Z" fill="white" />
    <path d="M11.1666 8V14.6667H13.8333V8H11.1666Z" fill="white" />
  </svg>
);
