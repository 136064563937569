import { TDesignCase } from '@Features/Explorer/Shared/Types/designCase';
import { getReferrer } from './getReferrer';
import { ALLOW_ORIGIN_LIST } from '../Constants/iframe';

export const onImportSuccess = (response: TDesignCase['id'] | File[]) => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage(
      { message: 'onSuccess', data: response },
      referrer
    );
  }
};

export const onRedirectApp = (url: string) => {
  const referrer = getReferrer();
  if (!ALLOW_ORIGIN_LIST.includes(referrer)) return;
  window.parent.postMessage(
    {
      message: 'onRedirect',
      data: { url: url }
    },
    referrer
  );
  console.log('App onRedirect request');
};

export const onCloseApp = () => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onClose' }, referrer);
  }
  console.log('App onClose request');
};

export const onError = () => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onError' }, referrer);
  }
  console.log('App onError request');
};
