import {
  Checkbox,
  IconButton,
  Popover,
  Ricon,
  Typography
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useCaseStatusFilterState } from '@Features/Shining3D/Shining3DImporterContent/Atoms/caseStatusFilter/useCaseStatusFilterState';
import { blue, grey } from '@imago-cloud/design-system/lib/esm/colors';

const CASE_STATUS_TYPE = [
  { name: 'Waiting to send', value: 'draft' },
  { name: 'Pending', value: 'waitAccept' },
  { name: 'Canceled', value: 'canceled' },
  { name: 'Refused', value: 'refused' },
  { name: 'Waiting design', value: 'waitDesign' },
  { name: 'Design plan confirming', value: 'waitConfirmDesign' },
  { name: 'Design uploading', value: 'waitDesignFile' },
  { name: 'Design confirming', value: 'waitConfirmDesignFile' },
  { name: 'Producing', value: 'waitDelivery' },
  { name: 'Shipping', value: 'waitConfirmDelivery' },
  { name: 'Finished', value: 'finished' }
];
export const OrderCaseListFilter = () => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const { caseStatusFilter, toggleFilterOption } = useCaseStatusFilterState();

  return (
    <Stack component={'div'}>
      <IconButton
        variant="transparent"
        hover_color="grey"
        icon_size="small"
        size="32"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <Ricon
          icon="ri-filter-2-fill"
          svgProps={{
            fill: caseStatusFilter.length > 0 ? blue.main : grey[800]
          }}
        />
      </IconButton>

      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          zIndex: 10001,
          '.MuiPaper-root.MuiPopover-paper': {
            width: '236px !important',
            padding: '12px 16px !important'
          }
        }}
      >
        <Stack direction="column" gap="6px" sx={{ width: '100%' }}>
          {CASE_STATUS_TYPE.map((status) => (
            <Stack key={status.value} flexDirection={'row'} gap={'6px'}>
              <Checkbox
                size="16"
                onChange={() => toggleFilterOption(status.value)}
                label={<Typography variant="Body14">{status.name}</Typography>}
              />
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};
