import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Typography
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useSessionExpiredDialogue } from '../Hooks/useSessionExpiredDialogue';
import { useTranslation } from 'react-i18next';

export const SessionExpiredDialogue = () => {
  const { isSessionExpired, onClick } = useSessionExpiredDialogue();
  const { t } = useTranslation();
  return (
    <Dialogue open={isSessionExpired}>
      <DialogueTitle>
        <Stack gap="12px" direction="row" alignItems="center">
          {t('meditLink:session_expired.dialog_title')}
        </Stack>
      </DialogueTitle>
      <DialogueContent>
        <Typography variant="Body18">
          {t('meditLink:session_expired.dialog_content')}
        </Typography>
      </DialogueContent>
      <DialogueActions>
        <Button
          variant="contained"
          size="36"
          color="primary"
          sx={{ minWidth: '100px' }}
          onClick={onClick}
        >
          {t('meditLink:session_expired.dialog_action_btn_ok')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
