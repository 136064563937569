import { atom } from 'recoil';

type TInitialData = {
  importType?: 'opener' | 'importer';
  designCaseId?: string;
  importHost?: string;
};

const defaultInitialData: TInitialData = {};

export const initialDataState = atom({
  key: 'initialData',
  default: defaultInitialData
});
