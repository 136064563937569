import { windowOpenCenter } from '@Shared/Utils/windowOpenCenter';

const getShining3DLoginUrl = (
  dentbirdUserId: string,
  dentbirdUserName: string
) =>
  `${process.env.REACT_APP_SHINING3D_LOGIN_URL}?codeChallenge=${process.env.REACT_APP_SHINING3D_CODE_CHALLENGE}&codeChallengeMethod=PLAIN&redirectUri=${process.env.REACT_APP_SHINING3D_REDIRECT_URL}&responseType=code&appId=${process.env.REACT_APP_SHINING3D_APP_ID}&thirdUserID=${dentbirdUserId}&thirdUserName=${dentbirdUserName}&switch=true`;

export const openShining3DWindow = (
  dentbirdUserId: string,
  dentbirdUserName: string
) => {
  const url = getShining3DLoginUrl(dentbirdUserId, dentbirdUserName);
  windowOpenCenter(url, 'shining3D', { width: 750, height: 900 });
};
