import { DialogueContent } from '@imago-cloud/design-system';
import { MeditCaseList } from './Components/MeditCaseList';
import { useMeditCaseState } from '../Shared/Atoms/meditCase/useMeditCaseState';
import { MeditFileList } from './Components/MeditFileList';
import { useGetOverflow } from '@Shared/Hooks/useGetOverflow';

export const MeditLinkImporterContent = () => {
  const { meditCase } = useMeditCaseState();
  const { y, containerRef } = useGetOverflow();

  return (
    <DialogueContent
      ref={containerRef}
      sx={{
        p: y ? '4px 0px 0px 20px' : '4px 14px 0px 20px',
        height: '100%'
      }}
    >
      {!meditCase ? <MeditCaseList /> : <MeditFileList />}
    </DialogueContent>
  );
};
