import { ExplorerDialogueActions } from '@Features/Explorer/ExplorerActions';
import { ExplorerDialogueContent } from '@Features/Explorer/ExplorerContent';
import { ExplorerDialogueTitle } from '@Features/Explorer/ExplorerHeader';
import CaseListPageProvider from '@Features/Explorer/Context/CaseListProvider';
import { Dialogue } from '@imago-cloud/design-system';

export const ExplorerDialogue = () => {
  return (
    <CaseListPageProvider>
      <Dialogue
        id="import_module_dialogue"
        open
        PaperProps={{
          sx: {
            width: '1120px !important',
            minWidth: '1120px',
            height: '836px !important',
            maxHeight: '836px'
          }
        }}
      >
        <ExplorerDialogueTitle />
        <ExplorerDialogueContent />
        <ExplorerDialogueActions />
      </Dialogue>
    </CaseListPageProvider>
  );
};
