export const MESH_DATA_EXTENSION_LIST = ['stl', 'ply', 'obj'];
export const TEXTURE_DATA_EXTENSION_LIST = ['png', 'jpg', 'jpeg', 'bmp', 'mtl'];

export const PRIORITY_IMPORT_FORMAT_TYPE = [
  'full_ply_color',
  'full_obj_color_1',
  'full_stl',
  'full_3shape_stl',
  'full_obj_color_255',
  'full_ply',
  'full_3shape_ply',
  'full_ply_exocad',
  'full_obj'
];
