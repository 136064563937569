import { useEffect, useState } from 'react';
import { MODULE_EXPLORER_TYPE } from '../Constants/initialData';
import { useInitialDataState } from '@Shared/Atoms/InitialData/useInitialDataState';
import { useNavigate } from 'react-router-dom';
import { IMPORT, MEDIT_LINK, SHINING3D } from '@Shared/Constants/routePath';
import { getReferrerCheck } from '@Shared/Utils/getReferrer';

export const useGetInitialDataFromHost = () => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(true);
  const { setInitialData } = useInitialDataState();
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const originURL = new URL(event.origin);
      const subDomain = originURL.hostname.split('.')[0];

      const { type, integration } = event.data || {};
      setInitialData({
        ...event.data,
        importType: type,
        importHost: subDomain
      });
      if (type === MODULE_EXPLORER_TYPE.OPENER) {
        navigate('/');
      } else if (type === MODULE_EXPLORER_TYPE.IMPORTER) {
        if (integration === 'meditLink') navigate(`${IMPORT}/${MEDIT_LINK}`);
        else if (integration === 'shining3d')
          navigate(`${IMPORT}/${SHINING3D}`);
        else navigate(IMPORT);
      } else {
        if (!getReferrerCheck()) setIsValid(false);
      }

      setDataLoaded(true);
    };

    if (!dataLoaded) window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dataLoaded]);

  // 후에 type에 따라 invalid 데이터 판단해주어야 함.
  return {
    dataLoaded,
    isValid
  };
};
