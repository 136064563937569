import { useRecoilState } from 'recoil';
import { meditCaseState } from '@Features/MeditLink/Shared/Atoms/meditCase/atom';

export const useMeditCaseState = () => {
  const [meditCase, setMeditCase] = useRecoilState(meditCaseState);

  return {
    meditCase,
    setMeditCase
  };
};
