import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useGetCaseList } from './Hooks/useGetCaseList';
import { NoCaseList } from './Components/NoCaseList';
import { Loading } from '@imago-cloud/design-system';
import { ScrollArea } from './Components/ScrollArea';
import { CaseListItem } from './Components/CaseListItem';
import { useCaseListIntersectionObserver } from '@Features/Explorer/ExplorerContent/Hooks/useCaseListIntersectionObserver';
import { useInitialDataState } from '@Shared/Atoms/InitialData/useInitialDataState';
import { useCaseListPageContext } from '@Features/Explorer/Context';
import { DATE_TYPE } from '@Features/Explorer/Shared/Constants/caseDateType';
import { useErrorState } from '@Shared/Atoms/Error/useErrorState';

export const ExplorerDialogueContent = () => {
  const { initialData } = useInitialDataState();
  const { designCaseList, isFetching, isError } = useGetCaseList();
  const { onUnknownError } = useErrorState();

  const loadingRef = useRef(null);

  useCaseListIntersectionObserver(loadingRef);

  const { queryOption } = useCaseListPageContext();
  const dateType = DATE_TYPE[queryOption.dateType];

  useEffect(() => {
    if (isError) onUnknownError();
  }, [isError]);

  return (
    <ScrollArea
      sx={{
        padding: '4px 14px 0 14px',
        overflow: 'hidden auto',
        height: '640px !important',
        maxHeight: '640px'
      }}
    >
      {designCaseList.length > 0 ? (
        <Stack flexDirection="column">
          {designCaseList.map((item) => (
            <CaseListItem
              key={item?.id ?? ''}
              item={item}
              dateType={dateType}
              isFetching={isFetching}
              nowDesignCaseId={initialData?.designCaseId ?? ''}
            />
          ))}
        </Stack>
      ) : (
        !isFetching && <NoCaseList />
      )}
      <Box ref={loadingRef}>
        {isFetching && (
          <Box
            sx={{
              mt: designCaseList.length ? '60px' : '120px',
              mb: '16px'
            }}
          >
            <Loading type="basic" size="24" />
          </Box>
        )}
      </Box>
    </ScrollArea>
  );
};
