import {
  Cicon,
  Dialogue,
  DialogueActions,
  DialogueTitle,
  Typography
} from '@imago-cloud/design-system';
import { onCloseApp } from '../../Utils/postMessage';
import { Stack } from '@mui/material';

export const UnknownErrorDialogue = () => {
  return (
    <Dialogue
      open
      PaperProps={{
        sx: {
          width: '1120px !important',
          minWidth: '1120px',
          height: '836px !important',
          maxHeight: '836px'
        }
      }}
    >
      <DialogueTitle
        isClose
        onClose={onCloseApp}
        sx={{ padding: '24px 20px 16px 28px', width: '100%', height: '68px' }}
      />
      <DialogueActions
        sx={{
          width: '100%',
          height: '768px',
          border: 'none',
          padding: '12px 28px 88px 28px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          gap="8px"
        >
          <Cicon icon="Warning" svgProps={{ width: '36px', height: '36px' }} />
          <Typography variant="H24">An unknown error has occurred</Typography>
        </Stack>
      </DialogueActions>
    </Dialogue>
  );
};
