import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getMeditCasesList,
  getMeditFileListByUUID,
  getMeditFileListInfo
} from './meditLinkApis';
import {
  MEDIT_CASE_LIST_KEY,
  MEDIT_FILE_LIST_INFO_KEY,
  MEDIT_FILE_LIST_KEY
} from '../../Shared/Constants/reactQueryKeys';

const useMeditCaseList = () => {
  return useInfiniteQuery(
    [MEDIT_CASE_LIST_KEY],
    ({ pageParam = 0 }) => getMeditCasesList(pageParam),
    {
      getNextPageParam: (data) => {
        let nextPage;
        if (!data.last) {
          nextPage = data.page + 1;
        }
        return nextPage;
      },
      retry: false,
      refetchOnWindowFocus: false
    }
  );
};

const useMeditFileListWithUUID = (uuid: string) => {
  return useQuery([MEDIT_FILE_LIST_KEY], () => getMeditFileListByUUID(uuid), {
    refetchOnWindowFocus: false,
    retry: false
  });
};

const useMeditFileListInfo = (fileUUIDList: string[]) => {
  return useQuery(
    [MEDIT_FILE_LIST_INFO_KEY, fileUUIDList],
    () => getMeditFileListInfo(fileUUIDList),
    {
      enabled: false
    }
  );
};

export { useMeditCaseList, useMeditFileListWithUUID, useMeditFileListInfo };
