import { useRecoilState } from 'recoil';
import { dateFilterState, defaultDateFilter } from './atom';

export const useDateFilterState = () => {
  const [dateFilter, setDateFilter] = useRecoilState(dateFilterState);

  const resetDateFilter = () => {
    setDateFilter(defaultDateFilter);
  };

  return {
    dateFilter,
    setDateFilter,
    resetDateFilter
  };
};
