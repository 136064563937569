import { Shining3DAuth } from '@Features/Shining3D/Authenticate';
import { Shining3DImporterHeader } from '@Features/Shining3D/Shining3DImporterHeader';
import { Dialogue } from '@imago-cloud/design-system';
import { Shining3DImporterContent } from '@Features/Shining3D/Shining3DImporterContent';
import { Shining3DImporterActions } from '@Features/Shining3D/Shining3DImporterActions';

export const Shining3DImporterPage = () => {
  return (
    <Shining3DAuth>
      <Dialogue
        id="import_module_dialogue"
        open
        PaperProps={{
          sx: {
            width: '1120px !important',
            minWidth: '1120px',
            height: '836px !important',
            maxHeight: '836px'
          }
        }}
      >
        <Shining3DImporterHeader />
        <Shining3DImporterContent />
        <Shining3DImporterActions />
      </Dialogue>
    </Shining3DAuth>
  );
};
