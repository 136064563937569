export const PRODUCT_TYPE = {
  CROWN: 'Crown',
  VIRTUAL_CROWN: 'Virtual crown',
  IMPLANT_CROWN: 'Crown',
  IN_ONLAY: 'In-onlay',
  PONTIC: 'Pontic'
} as const;

export const PRODUCT_GROUP_TYPE = {
  BRIDGE: 'Bridge'
} as const;

export const UNIT = {
  TOOTH: 'Tooth',
  ARCH: 'Arch'
};

export const MODELER_UNIT_TYPE = {
  UPPER: 'Upper model',
  LOWER: 'Lower model'
} as const;
