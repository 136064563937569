import { Stack } from '@mui/material';
import { Typography } from '@imago-cloud/design-system';
import { OrderCaseListFilter } from '@Features/Shining3D/Shining3DImporterContent/Components/OrderCaseListFilter';
import { useTranslation } from 'react-i18next';

export const OrderCaseListHeader = () => {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      sx={{
        height: '54px',
        padding: '15px 40px 15px 40px',
        boxShadow: 'inset 0px -1px 0px #DEE1E5'
      }}
      alignItems="center"
      gap="12px"
    >
      <Typography
        sx={{ width: '180px', color: 'grey.800' }}
        variant="Subtitle_Semibold16"
      >
        {t('shining3D:select_order.list_header_upload_at')}
      </Typography>
      <Typography
        sx={{ width: '180px', color: 'grey.800' }}
        variant="Subtitle_Semibold16"
      >
        {t('shining3D:select_order.list_header_doctor')}
      </Typography>
      <Typography
        sx={{ width: '220px', color: 'grey.800' }}
        variant="Subtitle_Semibold16"
      >
        {t('shining3D:select_order.list_header_clinichospital')}
      </Typography>
      <Typography
        sx={{ width: '220px', color: 'grey.800' }}
        variant="Subtitle_Semibold16"
      >
        {t('shining3D:select_order.list_header_patient_name')}
      </Typography>
      <Stack
        sx={{ width: '180px' }}
        gap="4px"
        direction="row"
        alignItems="center"
      >
        <Typography sx={{ color: 'grey.800' }} variant="Subtitle_Semibold16">
          {t('shining3D:select_order.list_header_status')}
        </Typography>
        <OrderCaseListFilter />
      </Stack>
    </Stack>
  );
};
