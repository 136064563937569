import { useRecoilState } from 'recoil';
import { selectedOrderCaseState } from '../selectedOrderCase/atom';
import { useSelectedInstitutionState } from '../selectedInstitution/useSelectedInstitutionState';
import { useEffect } from 'react';

export const useSelectedOrderCaseState = () => {
  const [selectedOrderCase, setSelectedOrderCase] = useRecoilState(
    selectedOrderCaseState
  );
  const { selectedInstitution } = useSelectedInstitutionState();

  useEffect(() => {
    setSelectedOrderCase(null);
  }, [selectedInstitution]);

  return { selectedOrderCase, setSelectedOrderCase };
};
