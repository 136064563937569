import axios from 'axios';
import { meditAxiosInstance } from './meditAxiosInstance';
import {
  MEDIT_GET_CASE_DETAIL_API,
  MEDIT_GET_CASE_LIST_API,
  MEDIT_GET_FILE_DETAIL_API
} from '../Constants/apiUrl';
import { Archive } from 'libarchive.js';

Archive.init({
  workerUrl: '/libarchive.js/dist/worker-bundle.js'
});

const getMeditCasesList = async (page: number) => {
  const requestUrl = `${MEDIT_GET_CASE_LIST_API}?size=20&page=${page}&start=0&end=253402300799000&status=SCAN,NEED_PROCESSING,CAD,CAM,MILL,COMPLETED`;
  const res = await meditAxiosInstance.get(requestUrl);
  return res.data;
};

const getMeditFileListByUUID = (uuid: string) =>
  meditAxiosInstance
    .get(MEDIT_GET_CASE_DETAIL_API(uuid))
    .then((res) => res.data);

const getMeditModelFileDetailInfo = (uuid: string) =>
  meditAxiosInstance
    .get(MEDIT_GET_FILE_DETAIL_API(uuid))
    .then((res) => res.data);

const getUnZipFileList = async (zipFileList: File[]) => {
  const unzipFileListPromise = zipFileList.map(async (el) => {
    const archive = await Archive.open(el);
    const extractFiles = await archive.extractFiles();

    return extractFiles[Object.keys(extractFiles)[0]];
  });

  return Promise.all(unzipFileListPromise);
};

const getMeditFileListInfo = async (fileUuidList: string[]) => {
  const promiseList = fileUuidList.map((el) => getMeditModelFileDetailInfo(el));

  const fileInfoList = await Promise.all(promiseList);

  const promiseZipFileList = fileInfoList.map((el) =>
    axios
      .get(el.url, {
        responseType: 'blob'
      })
      .then((res) => res.data)
  );

  const zipFileList = await Promise.all(promiseZipFileList);

  return getUnZipFileList(zipFileList);
};

export { getMeditFileListByUUID, getMeditFileListInfo, getMeditCasesList };
