import { useCallback, useRef } from 'react';

export const useCallOnce = (callback: () => void) => {
  const isProcessing = useRef(false);

  return useCallback(() => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    callback();
    isProcessing.current = false;
  }, [callback]);
};

export const useCallOnceAsync = (callback: () => Promise<void>) => {
  const isProcessing = useRef(false);

  return useCallback(async () => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    await callback();
    isProcessing.current = false;
  }, [callback]);
};
