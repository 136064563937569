import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueTitle,
  Ricon,
  theme,
  Typography
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const FileConversionFailedDialogue = ({
  onCancelClick
}: {
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialogue
      open
      PaperProps={{
        sx: {
          maxWidth: '540px',
          width: 'calc(100% - 64px) !important',
          position: 'relative'
        }
      }}
    >
      <DialogueTitle>
        {t('shining3D:conversion_failed.dialog_title_file_conversion')}
      </DialogueTitle>
      <Stack sx={{ m: '28px', mt: '0px', gap: '24px' }}>
        <Typography variant={'Body16'}>
          {t('shining3D:conversion_failed.error_contents_contact_shining_3d')}
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            backgroundColor: '#CC464614',
            p: '12px',
            gap: '8px',
            borderRadius: '8px'
          }}
        >
          <Stack sx={{ mt: '2px' }}>
            <Ricon
              icon="ri-error-warning-fill"
              svgProps={{
                width: '18px',
                height: '18px',
                fill: theme.palette.error.main
              }}
            />
          </Stack>
          <Typography variant="Body14" sx={{ color: theme.palette.error.main }}>
            {t('shining3D:conversion_failed.error_info_15days')}
          </Typography>
        </Stack>
      </Stack>
      <DialogueActions>
        <Button
          sx={{ minWidth: '100px' }}
          variant="outlined"
          size="36"
          color="grey"
          onClick={onCancelClick}
        >
          {t('shining3D:banner_alert.btn_cancel')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
