import { Suspense, useEffect } from 'react';
import { useGetInitialDataFromHost } from '@Shared/Hooks/useGetInitialDataFromHost';
import { AppLoading } from '@Shared/Components/AppLoading';
import { getReferrerCheck } from '@Shared/Utils/getReferrer';
import { useErrorState } from '@Shared/Atoms/Error/useErrorState';

export const AppSuspense = ({
  children
}: {
  children: React.ReactNode | null;
}) => {
  const initialData = useGetInitialDataFromHost();
  const { isValid, dataLoaded } = initialData;
  const isValidReferrer = getReferrerCheck();
  const { onUnknownError } = useErrorState();

  useEffect(() => {
    if (!isValid || !isValidReferrer) {
      onUnknownError();
    }
  }, [isValid, isValidReferrer]);

  if (!dataLoaded) {
    return <AppLoading />;
  }

  return <Suspense fallback={<AppLoading />}>{children}</Suspense>;
};
