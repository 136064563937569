import { AppSuspense } from './AppSuspense';
import { AuthLoader } from '@Shared/Components/AuthLoader';
import { Outlet } from 'react-router-dom';

export const AppLayout = () => {
  return (
    <AuthLoader>
      <AppSuspense>
        <Outlet />
      </AppSuspense>
    </AuthLoader>
  );
};
