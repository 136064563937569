import { useOrderListInfinite } from '@Features/Shining3D/Shining3DImporterContent/Apis/queries';
import { TOrderCase } from '@Features/Shining3D/Shared/Types/orderCase';
import { useCaseStatusFilterState } from '@Features/Shining3D/Shining3DImporterContent/Atoms/caseStatusFilter/useCaseStatusFilterState';

export const useFilteredOrderCaseList = () => {
  const { data, ...rest } = useOrderListInfinite();
  const { caseStatusFilter } = useCaseStatusFilterState();
  const orderCaseList: TOrderCase[] =
    data?.pages.flatMap((item) => item.result || []) || [];
  const filteredOrderCaseList =
    caseStatusFilter.length === 0
      ? orderCaseList
      : orderCaseList.filter((caseItem) =>
          caseStatusFilter.includes(caseItem.status)
        );

  return { filteredOrderCaseList, ...rest };
};
