import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { UNZIPPED_SHINING3D_ORDER_CASE } from '../../Shared/Constants/reactQueryKeys';
import { useUnzippedOrderCaseFile } from '../Apis/queries';
import { getReferrer } from '@Shared/Utils/getReferrer';
import { ALLOW_ORIGIN_LIST } from '@Shared/Constants/iframe';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useErrorState } from '@Shared/Atoms/Error/useErrorState';

const onImportSuccess = (res: any) => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onSuccess', data: res }, referrer);
  }
};

export const useShining3DFileTransfer = () => {
  const [isTransferring, setIsTransferring] = useState(false);
  const [isConversionFailed, setIsConversionFailed] = useState(false);
  const { onUnknownError } = useErrorState();
  const queryClient = useQueryClient();
  const { refetch } = useUnzippedOrderCaseFile();

  const onImportClick = async () => {
    setIsTransferring(true);

    try {
      const { status, data } = await refetch();

      if (status === 'error') setIsConversionFailed(true);
      else {
        onImportSuccess(data);
        onCloseApp();
      }
    } catch (err) {
      onUnknownError();
    } finally {
      setIsTransferring(false);
    }
  };

  const cancelTransferring = () => {
    setIsTransferring(false);
    queryClient.cancelQueries([UNZIPPED_SHINING3D_ORDER_CASE]);
  };

  return {
    onImportClick,
    setIsConversionFailed,
    isTransferring,
    isConversionFailed,
    cancelTransferring
  };
};
