import { Stack } from '@mui/material';
import { TOrderCase } from '@Features/Shining3D/Shared/Types/orderCase';
import { Typography } from '@imago-cloud/design-system';
import dayjs from 'dayjs';
import { CaseStatusLabel } from '@Features/Shining3D/Shining3DImporterContent/Components/CaseStatusLabel';
import { useSelectedOrderCaseState } from '@Features/Shining3D/Shared/Atoms/selectedOrderCase/useSelectedOrderCaseState';

export const OrderCaseListItem = ({ orderCase }: { orderCase: TOrderCase }) => {
  const { selectedOrderCase, setSelectedOrderCase } =
    useSelectedOrderCaseState();
  return (
    <Stack
      direction="row"
      sx={{
        minHeight: '64px',
        height: '64px',
        padding: '20px 24px 20px 20px',
        borderRadius: '8px',
        mb: '4px',
        cursor: 'pointer',
        color: selectedOrderCase?.id === orderCase.id ? 'blue.500' : 'grey.900',
        backgroundColor:
          selectedOrderCase?.id === orderCase.id ? 'blue.10' : 'transparent',
        '&:hover': {
          backgroundColor: 'action.hover'
        },
        '&:active': {
          backgroundColor: 'grey.200'
        }
      }}
      onClick={() => setSelectedOrderCase(orderCase)}
      alignItems="center"
      gap="12px"
    >
      <Typography
        variant="Body16"
        sx={{
          width: '180px'
        }}
      >
        {dayjs(orderCase.createOn).format('YYYY-MM-DD HH:mm:ss')}
      </Typography>
      <Typography
        variant="Body16"
        sx={{
          width: '180px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {orderCase.doctor.name}
      </Typography>
      <Typography
        variant="Body16"
        sx={{
          width: '220px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {orderCase.hospital.name}
      </Typography>
      <Typography
        variant="Body16"
        sx={{
          width: '220px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {orderCase.patient.name}
      </Typography>
      <CaseStatusLabel status={orderCase.status} />
    </Stack>
  );
};
