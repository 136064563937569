import { InstitutionSelect } from './InstitutionSelect';
import { DateRangePicker } from '@Shared/Components/DateRangePicker';
import { DialogueTitle } from '@imago-cloud/design-system';
import { useDateFilterState } from '@Features/Shining3D/Shared/Atoms/dateFilter/useDateFilterState';
import { useMemo } from 'react';

const MENU_LIST = ['Today', 'Last 7 Days', 'Last 30 Days'];
export const OrderCaseTitle = () => {
  const { dateFilter, setDateFilter } = useDateFilterState();
  const defaultDate = useMemo(() => dateFilter, []);
  return (
    <DialogueTitle
      sx={{
        px: '28px',
        pb: '12px',
        '& > div': {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }
      }}
    >
      <InstitutionSelect />
      <DateRangePicker
        handleDateChange={setDateFilter}
        size={'48'}
        menuList={MENU_LIST}
        defaultMenu={'Last 30 Days'}
        defaultDate={defaultDate}
        limitDays={29}
      />
    </DialogueTitle>
  );
};
