const THIRD_PARTY_FACTORIES_KEY = 'shining3DThirdPartyFactories';
const SHINING3D_ORDER_LIST = 'shining3DOrderList';
const SHINING3D_ORDER_DETAIL = 'shining3DOrderDetail';
const UNZIPPED_SHINING3D_ORDER_CASE = 'unzippedShining3DOrderCase';
export {
  THIRD_PARTY_FACTORIES_KEY,
  SHINING3D_ORDER_LIST,
  SHINING3D_ORDER_DETAIL,
  UNZIPPED_SHINING3D_ORDER_CASE
};
