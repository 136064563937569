import {
  Button,
  Cicon,
  Dialogue,
  DialogueActions,
  DialogueTitle,
  Typography
} from '@imago-cloud/design-system';
import { DialogContent, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DESIGN_CASE_LIST_KEY } from '../../../Shared/Constants/reactQueryKey';
import { useTranslation } from 'react-i18next';

interface ICloseButtonOnlyDialogueProps {
  open: boolean;
  onClose: () => void;
}

export const OpenFailedDialogue = ({
  open,
  onClose
}: ICloseButtonOnlyDialogueProps) => {
  const queryClient = useQueryClient();
  const onOKButtonClick = () => {
    onClose();
    queryClient.invalidateQueries({ queryKey: [DESIGN_CASE_LIST_KEY] });
  };
  const { t } = useTranslation();

  return (
    <Dialogue open={open}>
      <DialogueTitle>
        <Stack gap="8px" flexDirection="row" alignItems="center">
          <Cicon
            icon="Warning"
            svgProps={{
              width: 22,
              height: 22
            }}
          />
          <Typography variant="H18" color="text.primary">
            {t('explorer:open_dialog.open_failed_title')}
          </Typography>
        </Stack>
      </DialogueTitle>
      <DialogContent>
        <Typography variant="Body16" color="text.primary">
          {t('explorer:open_dialog.open_failed_contents')}
        </Typography>
      </DialogContent>
      <DialogueActions>
        <Button
          variant="contained"
          size="36"
          color="primary"
          sx={{ minWidth: '100px' }}
          onClick={onOKButtonClick}
        >
          {t('explorer:open_dialog.open_failed_action_btn_ok')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
