import { useSelectedInstitutionState } from '../Shared/Atoms/selectedInstitution/useSelectedInstitutionState';
import { InstitutionListHeader } from './Components/InstitutionListHeader';
import { Suspense } from 'react';
import { LoadingFallback } from '@Shared/Components/LoadingFallback';
import { DialogueContent } from '@imago-cloud/design-system';
import { useGetOverflow } from '@Shared/Hooks/useGetOverflow';
import { OrderCaseListHeader } from '@Features/Shining3D/Shining3DImporterContent/Components/OrderCaseListHeader';
import { InstitutionList } from '@Features/Shining3D/Shining3DImporterContent/Components/InstitutionList';
import { OrderCaseList } from '@Features/Shining3D/Shining3DImporterContent/Components/OrderCaseList';

export const Shining3DImporterContent = () => {
  const { selectedInstitution } = useSelectedInstitutionState();
  const { y, containerRef } = useGetOverflow();

  return (
    <>
      {!selectedInstitution ? (
        <InstitutionListHeader />
      ) : (
        <OrderCaseListHeader />
      )}

      <>
        <DialogueContent
          ref={containerRef}
          sx={{
            p: y ? '4px 0px 0px 20px' : '4px 14px 0px 20px',
            height: '100%'
          }}
        >
          <Suspense fallback={<LoadingFallback />}>
            {!selectedInstitution ? <InstitutionList /> : <OrderCaseList />}
          </Suspense>
        </DialogueContent>
      </>
    </>
  );
};
