import {
  Button,
  DialogueActions,
  Ricon,
  Typography
} from '@imago-cloud/design-system';
import { useMeditCaseState } from '../Shared/Atoms/meditCase/useMeditCaseState';
import { Stack } from '@mui/material';
import { useMeditSelectedFileListState } from '../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';
import { useMeditFileTransfer } from './Hooks/useMeditFileTransfer';
import { FileTransferring } from './Components/FileTransferring';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useTranslation } from 'react-i18next';

export const MeditLinkImporterActions = () => {
  const { meditCase } = useMeditCaseState();
  const { fileList } = useMeditSelectedFileListState();
  const { isTransferring, onImportClick } = useMeditFileTransfer();
  const { t } = useTranslation();

  return (
    <>
      <DialogueActions sx={{ paddingLeft: '20px' }}>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: !meditCase ? 'space-between' : 'flex-end'
          }}
        >
          {!meditCase && (
            <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
              <Ricon
                icon="ri-information-line"
                svgProps={{ width: '24px', height: '24px', fill: '#333' }}
              />
              <Typography variant={'Body16'}>
                {t('meditLink:case_selection.info_txt')}
              </Typography>
            </Stack>
          )}

          <Stack sx={{ flexDirection: 'row', gap: '10px' }}>
            <Button
              size={'36'}
              color={'grey'}
              variant={'outlined'}
              sx={{ minWidth: '100px' }}
              onClick={onCloseApp}
            >
              <Typography variant={'Button_Bold16'}>
                {t('meditLink:banner_alert.btn_cancel')}
              </Typography>
            </Button>
            <Button
              size={'36'}
              color={'primary'}
              sx={{ minWidth: '100px' }}
              disabled={!meditCase || fileList.length === 0}
              onClick={onImportClick}
            >
              <Typography variant={'Button_Bold16'}>
                {t('meditLink:btn_stack.action_btn_import')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </DialogueActions>
      {isTransferring && <FileTransferring />}
    </>
  );
};
