import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en';
import ja from './locales/ja';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';
import ko from './locales/ko';
import pt from './locales/pt';
import zh_CN from './locales/zh-CN';

i18n.use(initReactI18next).init({
  resources: {
    en,
    ja,
    es,
    fr,
    it,
    ko,
    pt,
    zh_cn: zh_CN
  },
  ns: ['explorer', 'meditLink', 'shining3D'],
  lng: 'en',
  lowerCaseLng: true,
  fallbackLng: 'en',
  fallbackNS: ['lokalise', 'designExportLang'],
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
