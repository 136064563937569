import { useMeditFileListInfo } from '../../Shared/Apis/meditLinkQueries';
import { useMeditSelectedFileListState } from '../../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';
import { useState } from 'react';
import { getReferrer } from '@Shared/Utils/getReferrer';
import { ALLOW_ORIGIN_LIST } from '@Shared/Constants/iframe';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { useMeditCaseState } from '../../Shared/Atoms/meditCase/useMeditCaseState';
import { IMPORT_PLATFORM } from '@Shared/Constants/importPlatform';
import { useErrorState } from '@Shared/Atoms/Error/useErrorState';

const onImportSuccess = (res: any) => {
  const referrer = getReferrer();
  if (ALLOW_ORIGIN_LIST.includes(referrer)) {
    window.parent.postMessage({ message: 'onSuccess', data: res }, referrer);
  }
};

export const useMeditFileTransfer = () => {
  const { fileList } = useMeditSelectedFileListState();
  const { refetch } = useMeditFileListInfo(fileList);
  const { meditCase } = useMeditCaseState();
  const [isTransferring, setIsTransferring] = useState(false);
  const { onUnknownError } = useErrorState();

  const onImportClick = async () => {
    setIsTransferring(true);
    try {
      const data = await refetch();
      onImportSuccess({
        fileList: data.data,
        caseName: meditCase?.name ?? 'Untitled',
        importPlatform: IMPORT_PLATFORM.MEDITLINK
      });
      onCloseApp();
    } catch (err) {
      onUnknownError();
    } finally {
      setIsTransferring(false);
    }
  };

  return { isTransferring, onImportClick };
};
