import { ReactNode, useEffect } from 'react';
import { AuthorizationBanner } from './AuthorizationBanner';
import { useShining3DLogin } from '../Hooks/useShining3DLogin';
import { useShining3DCookies } from '../../Shared/Hooks/useShining3DCookies';
import { useCallOnceAsync } from '@Shared/Hooks/useCallOnce';
import { AppLoading } from '@Shared/Components/AppLoading';

export const Shining3DAuth = ({ children }: { children: ReactNode }) => {
  const {
    needLoading,
    isLoginProcessing,
    loginToShining3DUsingRefreshToken,
    cancelLoginProcess,
    loginToShining3DUsingCallbackCode
  } = useShining3DLogin();

  const loginToShining3DUsingCallbackCodeOnce = useCallOnceAsync(
    loginToShining3DUsingCallbackCode
  );
  const loginToShining3DUsingRefreshTokenOnce = useCallOnceAsync(
    loginToShining3DUsingRefreshToken
  );

  const { callbackCode, accessToken, connectionToken } = useShining3DCookies();

  const needLogin = !accessToken || !connectionToken;

  useEffect(() => {
    if (needLoading) return;

    if (callbackCode && isLoginProcessing) {
      loginToShining3DUsingCallbackCodeOnce();
    } else {
      loginToShining3DUsingRefreshTokenOnce();
    }
  }, [needLoading, callbackCode]);

  if (needLoading) return <AppLoading />;

  return (
    <>
      {isLoginProcessing && (
        <AuthorizationBanner onCancelClick={cancelLoginProcess} />
      )}
      {!needLogin && children}
    </>
  );
};
