import { Label } from '@imago-cloud/design-system';
import { ILabel } from '@imago-cloud/design-system/lib/esm/Components/DataDisplay/Label/Label';
import React from 'react';
import { useCaseStatus } from '../../Hooks/useCaseStatus';
import { useTranslation } from 'react-i18next';

interface CaseStatusLabelProps {
  label: string;
}

const CaseStatusLabel = ({ label }: CaseStatusLabelProps) => {
  const { getCaseStatus } = useCaseStatus();
  const { value, text, i18nKey } = getCaseStatus(label);
  const { t } = useTranslation();

  const styles: { variant: ILabel['variant']; color: ILabel['color'] } =
    (() => {
      switch (value) {
        case 'Created':
          return {
            variant: 'outlined',
            color: 'dark'
          };
        case 'Designed':
          return { variant: 'ghost', color: 'dark' };
        case 'NC generated':
          return { variant: 'ghost', color: 'success' };
        default:
          return { variant: undefined, color: undefined };
      }
    })();

  return (
    <Label
      variant={styles.variant}
      color={styles.color}
      data-cy="caseStatusLabel"
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 'fit-content'
      }}
    >
      {i18nKey ? t(i18nKey) : text}
    </Label>
  );
};

export default CaseStatusLabel;
