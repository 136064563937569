export const useCaseStatus = () => {
  const getCaseStatus = (label: string) => {
    switch (label) {
      case 'CREATED':
        return {
          text: 'Created',
          value: 'Created',
          i18nKey: 'explorer:ui_component_list_hd.menu_created' as const
        };
      case 'Imported':
        return {
          text: 'Created',
          value: 'Created',
          i18nKey: 'explorer:ui_component_list_hd.menu_created' as const
        };
      case 'DESIGNED':
        return {
          text: 'Designed',
          value: 'Designed',
          i18nKey: 'explorer:ui_component_status.designed' as const
        };
      case 'CAM':
        return {
          text: 'NC generated',
          value: 'NC generated'
        };
      default:
        return {
          text: 'Created',
          value: 'Created',
          i18nKey: 'explorer:ui_component_list_hd.menu_created' as const
        };
    }
  };

  return { getCaseStatus };
};
