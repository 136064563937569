import { shining3DAxiosInstance } from '../../Shared/Apis/axiosInstance';
import { TOrderCase } from '../../Shared/Types/orderCase';
import { TUserFactory } from '../../Shared/Types/factory';
import {
  filter3dModelFile,
  findPriorityAttachType
} from '../Utils/filterFiles';
import { getEncryptData } from '@Shared/Utils/getEncryptData';
import axios from 'axios';
import { Archive } from 'libarchive.js';
import { FilesObject } from 'libarchive.js/src/libarchive';
import {
  SHINING_3D_ORDER_DETAIL_URL,
  SHINING_3D_ORDER_DOWNLOAD_URL
} from '../Constants/apiUrl';
import { IMPORT_PLATFORM } from '@Shared/Constants/importPlatform';

export const getOrderFileDetailInfo = async (
  id: string,
  orgCode: string,
  orgType: string,
  token: string
) => {
  const { status, result } = await shining3DAxiosInstance
    .get(SHINING_3D_ORDER_DETAIL_URL, {
      params: { id, orgType, orgCode },
      headers: {
        'X-Auth-AppKey': process.env.REACT_APP_SHINING3D_APP_KEY ?? '',
        'X-Auth-Token': token
      }
    })
    .then((res) => res.data);

  if (status === 'success') {
    return result;
  } else {
    throw Error('order detail fetch failed');
  }
};

export const getUnzippedOrderCaseFile = async ({
  csrf,
  orderCase,
  institution,
  connectionToken
}: {
  csrf: string;
  orderCase: TOrderCase;
  institution: TUserFactory;
  connectionToken: string;
}) => {
  const data = await getOrderFileDetailInfo(
    orderCase.id,
    institution.orgCode,
    institution.orgType,
    connectionToken
  );

  const attachType = data && findPriorityAttachType(data.attachs);

  if (!attachType) throw Error('attach type is not found');

  const orderCaseFile = await getOrderCaseFile(
    csrf,
    {
      id: orderCase.id,
      orgCode: institution.orgCode,
      attachType
    },
    connectionToken
  );

  const { filename, downloadURL } = orderCaseFile[0];

  const fileList = await extractNestedZipFiles(filename, downloadURL);

  return {
    fileList,
    caseName: filename.split('.')[0],
    importPlatform: IMPORT_PLATFORM.SHINING3D
  };
};

const getOrderCaseFile = async (
  csrf: string,
  bodyData: {
    orgCode: string;
    id: string;
    attachType: string;
  },
  token: string
) => {
  const encryptedData = await getEncryptData(bodyData, csrf);
  const { status, result } = await shining3DAxiosInstance
    .post(SHINING_3D_ORDER_DOWNLOAD_URL, encryptedData, {
      headers: {
        'X-Auth-AppKey': process.env.REACT_APP_SHINING3D_APP_KEY ?? '',
        'X-Auth-Token': token,
        isCsrf: 'true',
        'X-Encrypt-AES': 'true',
        'X-Auth-CSRF': csrf,
        'Content-Type': 'text/plain'
      }
    })
    .then((res) => res.data);

  if (status === 'success') {
    return result;
  } else {
    throw Error('file download failed');
  }
};

const extractNestedZipFiles = async (fileName: string, url: string) => {
  // (url: string)
  const blob = await axios
    .get(url, {
      responseType: 'blob'
    })
    .then((res) => res.data);

  const zipFile = new File([blob], `${fileName}`);

  if (!fileName.endsWith('.zip')) {
    return [zipFile];
  }

  const extractedFiles: File[] = [];

  await extractFilesRecursively(zipFile, extractedFiles);

  return filter3dModelFile(extractedFiles);
};
const extractFilesRecursively = async (
  zipFile: File,
  extractedFiles: File[]
) => {
  const zipArchive = await Archive.open(zipFile);
  const archiveContents = await zipArchive.extractFiles();
  delete archiveContents['__MACOSX']; // Mac 숨김 파일 제거

  for (const filename in archiveContents) {
    const innerFiles = archiveContents[filename];
    delete (innerFiles as FilesObject)['.DS_Store']; // Mac 숨김 파일 제거

    if (filename.endsWith('zip')) {
      await extractFilesRecursively(innerFiles as File, extractedFiles);
    } else {
      extractedFiles.push(innerFiles as File);
    }
  }
};
