import { useSelectedInstitutionState } from '../Shared/Atoms/selectedInstitution/useSelectedInstitutionState';
import { InstitutionTitle } from './Components/InstitutionTitle';
import { OrderCaseTitle } from './Components/OrderCaseTitle';

export const Shining3DImporterHeader = () => {
  const { selectedInstitution } = useSelectedInstitutionState();

  return (
    <>{!selectedInstitution ? <InstitutionTitle /> : <OrderCaseTitle />}</>
  );
};
