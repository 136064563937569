import { Stack } from '@mui/material';
import {
  Alert,
  Button,
  Snackbar,
  Typography
} from '@imago-cloud/design-system';
import { useTranslation } from 'react-i18next';

export const AuthorizationBanner = ({
  onCancelClick
}: {
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 700,
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}
    >
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        open
      >
        <Alert
          sx={{ width: '960px' }}
          severity="info"
          title={t('shining3D:banner_alert.title_in_progress')}
          action={
            <Button
              color="white"
              variant="text"
              size="36"
              onClick={onCancelClick}
            >
              {t('shining3D:banner_alert.btn_cancel')}
            </Button>
          }
        >
          <Typography variant="Body14" component="span">
            {t('shining3D:banner_alert.body_not_showing')}
          </Typography>
        </Alert>
      </Snackbar>
    </Stack>
  );
};
