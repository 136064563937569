import {
  Button,
  Chip,
  DatePicker,
  Divider,
  IconButton,
  IconButtonProps,
  MenuItem,
  Popover,
  Ricon,
  Typography
} from '@imago-cloud/design-system';
import { blue, grey } from '@imago-cloud/design-system/lib/esm/colors';
import { Box, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDateRangePicker } from '@Shared/Hooks/useDateRangePicker';
import { TDate } from '@Shared/Types/dateType';
import { useTranslation } from 'react-i18next';

const MENU_LIST = ['All', 'Today', 'Last 7 Days', 'Last 30 Days'];

const DATE_MAP = {
  All: 'explorer:ui_component.calendar_menu_all' as const,
  Today: 'explorer:ui_component.calendar_menu_today' as const,
  'Last 7 Days': 'explorer:ui_component.calendar_menu_last_7_days' as const,
  'Last 30 Days': 'explorer:ui_component.calendar_menu_last_30_days' as const
};

export interface IDateRangePickerReturn {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export interface DateRangePickerProps {
  handleDateChange: (
    value: IDateRangePickerReturn,
    callbackReset?: (all: 'All') => void
  ) => void;
  size?: IconButtonProps['size'];
  menuList?: string[];
  defaultMenu?: string;
  defaultDate?: TDate;
  limitDays?: number;
}

export const DateRangePicker = ({
  handleDateChange,
  size,
  menuList = MENU_LIST,
  defaultMenu,
  defaultDate,
  limitDays
}: DateRangePickerProps) => {
  const {
    handleDatePickerClick,
    selectedCustomDate,
    handleCustomStartDateChange,
    handleCustomEndDateChange,
    handleCustomDateApplyClick,
    isDateFilterOpen,
    dateFilterType,
    isCustomDatePickerOpen,
    anchorEl,
    handleButtonClick,
    handleClose,
    chipLabel,
    isEnableCustomData,
    isOpenEndDate,
    isOpenStartDate,
    isDefaultDate
  } = useDateRangePicker(handleDateChange, defaultMenu, defaultDate, limitDays);

  const { t } = useTranslation();

  const dateLimitOption = {
    maxDate: selectedCustomDate.startDate?.add(limitDays as number, 'days'),
    defaultCalendarMonth: selectedCustomDate.startDate
      ?.add(limitDays as number, 'days')
      .isAfter(dayjs(), 'month')
      ? dayjs()
      : selectedCustomDate.startDate?.add(limitDays as number, 'days'),
    components: {
      ActionBar: () => <DateRangePickerNotice limitDays={limitDays as number} />
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      {(dateFilterType !== 'All' || isCustomDatePickerOpen) && (
        <Chip
          label={chipLabel}
          sx={{ mr: '8px' }}
          onDelete={
            isDefaultDate && !isCustomDatePickerOpen
              ? undefined
              : () => handleDatePickerClick(defaultMenu ?? 'All')
          }
          data-cy="dateLabel"
        />
      )}
      <IconButton
        variant="outlined"
        size={size ?? '36'}
        icon_size="small"
        hover_color="grey"
        onClick={handleButtonClick}
        data-cy="datePickerButton"
      >
        <Ricon
          icon={
            dateFilterType === 'All'
              ? 'ri-calendar-line'
              : 'ri-calendar-check-line'
          }
          svgProps={{
            fill: dateFilterType === 'All' ? grey[800] : blue.main
          }}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={isDateFilterOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          mt: '8px'
        }}
        PaperProps={{
          sx: {
            width: '200px !important',
            padding: '8px !important'
          }
        }}
      >
        {menuList.map((item, index) => (
          <MenuItem
            key={item}
            onClick={() => handleDatePickerClick(item)}
            selected={dateFilterType === item && !isCustomDatePickerOpen}
            data-cy={`date-${item}`}
          >
            {t(DATE_MAP[item as keyof typeof DATE_MAP])}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          key="Custom"
          onClick={() => handleDatePickerClick('Custom')}
          selected={isCustomDatePickerOpen}
        >
          {t('explorer:ui_component.calendar_menu_custom')}
        </MenuItem>
        {isCustomDatePickerOpen && (
          <Stack>
            <Stack
              direction="row"
              sx={{ p: '16px 10px' }}
              alignItems="flex-end"
            >
              <Box
                sx={{ width: '20px', height: '20px', mb: '6px' }}
                alignItems="center"
              >
                <Typography variant="Body16">~</Typography>
              </Box>
              <Stack gap={'10px'}>
                <DatePicker
                  open={isOpenStartDate}
                  value={selectedCustomDate.startDate}
                  placeholder={'Start Date'}
                  onAccept={(value) =>
                    handleCustomStartDateChange(value as Dayjs)
                  }
                  inputFormat={'YYYY.MM.DD'}
                  {...(limitDays && dateLimitOption)}
                  maxDate={selectedCustomDate.endDate}
                  disableFuture
                  disableHighlightToday
                />
                <DatePicker
                  open={isOpenEndDate}
                  value={selectedCustomDate.endDate}
                  placeholder={'End Date'}
                  onAccept={(value) =>
                    handleCustomEndDateChange(value as Dayjs)
                  }
                  {...(limitDays && dateLimitOption)}
                  inputFormat={'YYYY.MM.DD'}
                  disableFuture
                  disableHighlightToday
                />
              </Stack>
            </Stack>
            <Button
              size="36"
              variant="text"
              color="blue"
              sx={{
                width: 'fit-content',
                alignSelf: 'flex-end',
                mb: '10px',
                mr: '10px'
              }}
              onClick={handleCustomDateApplyClick}
              disabled={!isEnableCustomData}
            >
              Apply
            </Button>
          </Stack>
        )}
      </Popover>
    </Stack>
  );
};

const DateRangePickerNotice = ({ limitDays }: { limitDays: number }) => {
  return (
    <Stack
      sx={{
        width: '320px',
        height: '84px',
        pb: '24px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      gap="4px"
    >
      <Stack
        sx={{
          mt: '20px',
          width: '264px',
          backgroundColor: 'blue.10',
          padding: '8px 16px',
          borderRadius: '8px'
        }}
      >
        <Typography variant="Body14" sx={{ color: 'primary.main' }}>
          You can retrieve data up to {limitDays + 1} days from the selected
          start date.
        </Typography>
      </Stack>
    </Stack>
  );
};
