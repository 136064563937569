import { atom } from 'recoil';

const defaultMeditUserInfo = {
  email: null
};

export const meditUserInfoState = atom({
  key: 'meditUserInfo',
  default: defaultMeditUserInfo
});
