import React from 'react';
import { Button, DialogueActions } from '@imago-cloud/design-system';
import { onCloseApp } from '@Shared/Utils/postMessage';
import { OpenFailedDialogue } from './Components/OpenFailedDialogue';
import { useExplorerActions } from './Hooks/useExplorerActions';
import { useTranslation } from 'react-i18next';

export const ExplorerDialogueActions = () => {
  const { open, isImportEnabled, onActionButtonClick, handleCloseDialogue } =
    useExplorerActions();
  const { t } = useTranslation();
  return (
    <>
      <DialogueActions sx={{ height: '68px' }}>
        <Button
          size="36"
          color="grey"
          shadow={true}
          hover_color="grey"
          variant="outlined"
          sx={{
            minWidth: '100px'
          }}
          onClick={onCloseApp}
        >
          {t('explorer:open_dialog.action_btn_cancel')}
        </Button>
        <Button
          size="36"
          color="primary"
          variant="contained"
          type="submit"
          sx={{ minWidth: '100px' }}
          disabled={!isImportEnabled}
          onClick={onActionButtonClick}
        >
          {t('explorer:open_dialog.action_btn_select')}
        </Button>
      </DialogueActions>
      <OpenFailedDialogue open={open} onClose={handleCloseDialogue} />
    </>
  );
};
