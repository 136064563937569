export const windowOpenCenter = (
  url: string | URL | undefined,
  target: string | undefined,
  features: { width: number; height: number }
) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const clientWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  const clientHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

  const systemZoom = clientWidth / window.screen.availWidth;
  const left = (clientWidth - features.width) / 2 / systemZoom + dualScreenLeft;
  const top = (clientHeight - features.height) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    target,
    `
    scrollbars=yes,
    width=${features.width},
    height=${features.height},
    top=${top},
    left=${left}
    minWidth=${features.width},
    minHeight=${features.height}
    `
  );

  if (newWindow) newWindow.focus();

  return newWindow;
};
