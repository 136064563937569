import { useContext } from 'react';
import { CaseListPageContext } from './CaseListProvider';

export const useCaseListPageContext = () => {
  const context = useContext(CaseListPageContext);

  if (!context) {
    throw new Error(
      `useCaseListPageContext cannot be used outside the CaseListPage`
    );
  }

  return context;
};
