import { useShining3DUserInfoState } from '@Features/Shining3D/Shared/Atoms/shining3DUserInfo/useShining3DUserInfoState';
import { useSelectedInstitutionState } from '@Features/Shining3D/Shared/Atoms/selectedInstitution/useSelectedInstitutionState';
import { MenuItem, Select } from '@imago-cloud/design-system';
import { ChangeEvent } from 'react';

export const InstitutionSelect = () => {
  const {
    userInfo: { factories }
  } = useShining3DUserInfoState();
  const { selectedInstitution, selectInstitution } =
    useSelectedInstitutionState();

  const onSelectInstitution = (e: ChangeEvent<HTMLInputElement>) => {
    selectInstitution(
      factories.find((factory) => factory.id === e.target.value) ?? null
    );
  };

  return (
    <Select
      size="48"
      label_visible="none"
      value={selectedInstitution?.id}
      onChange={onSelectInstitution}
      sx={{ width: '320px' }}
      SelectProps={{
        MenuProps: {
          sx: {
            '.MuiPaper-root': {
              maxHeight: '216px',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '120px'
              },
              '&::-webkit-scrollbar-thumb': {
                minHeight: '10%',
                border: '3px solid transparent',
                backgroundClip: 'content-box',
                borderRadius: '12px',
                backgroundColor: 'rgba(0, 0, 0, 0.23)'
              },
              padding: '8px'
            },
            '.MuiList-root ': {
              padding: 0
            },
            '.MuiMenuItem-root': {
              height: '40px'
            }
          }
        }
      }}
    >
      {factories.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};
