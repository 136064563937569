import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { DateRangePickerProps } from '../Components/DateRangePicker';
import { TDate } from '@Shared/Types/dateType';

export type UseDateRangePicker = ReturnType<typeof useDateRangePicker>;

export const useDateRangePicker = (
  handleDateChange: DateRangePickerProps['handleDateChange'],
  defaultMenu?: string,
  defaultDate?: TDate,
  limitDays?: number
) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [dateFilterType, setDateFilterType] = useState(defaultMenu ?? 'All');
  const [prevDateFilterType, setPrevDateFilterType] = useState(
    defaultMenu ?? 'All'
  );

  const [selectedDate, setSelectedDate] = useState<TDate>(
    defaultDate ?? {
      startDate: null,
      endDate: null
    }
  );
  const [selectedCustomDate, setSelectedCustomDate] = useState<TDate>(
    defaultDate ?? {
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate
    }
  );

  const isCustomDatePickerOpen = dateFilterType === 'Custom';

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleDatePickerClick = (dateType: string) => {
    if (dateType === 'Custom') {
      setDateFilterType(dateType);
      return;
    }
    const newSelectedDate = (() => {
      switch (dateType) {
        case 'Today':
          return {
            startDate: dayjs().startOf('day'),
            endDate: dayjs().endOf('day')
          };
        case 'Last 7 Days':
          return {
            startDate: dayjs().subtract(6, 'days').startOf('day'),
            endDate: dayjs().endOf('day')
          };
        case 'Last 30 Days':
          return {
            startDate: dayjs().subtract(29, 'days').startOf('day'),
            endDate: dayjs().endOf('day')
          };
        case 'All':
          return {
            startDate: null,
            endDate: null
          };
        default:
          return {
            startDate: null,
            endDate: null
          };
      }
    })();
    setDateFilterType(dateType);
    setPrevDateFilterType(dateType);
    setSelectedDate(newSelectedDate);
    handleDateChange(newSelectedDate);
    setSelectedCustomDate(newSelectedDate);
    setAnchorEl(null);
  };

  const handleCustomStartDateChange = (date: Dayjs) => {
    const newStartDate = date;
    let newEndDate: Dayjs | null = selectedCustomDate.endDate;

    if (newEndDate && newStartDate) {
      if (newEndDate?.isBefore(newStartDate, 'day')) {
        newEndDate = null;
      }
    }

    if (limitDays) {
      if (newEndDate?.isAfter(newStartDate.add(limitDays, 'days'))) {
        newEndDate = null;
      }
    }

    setSelectedCustomDate({ startDate: newStartDate, endDate: newEndDate });
  };

  const handleCustomEndDateChange = (date: Dayjs) => {
    let newStartDate: Dayjs | null = selectedCustomDate.startDate;
    let newEndDate: Dayjs | null = date;

    if (newStartDate && newEndDate) {
      if (newEndDate?.isBefore(newStartDate, 'day')) {
        newStartDate = newEndDate;
        newEndDate = null;
      }
    }

    setSelectedCustomDate({ startDate: newStartDate, endDate: newEndDate });
  };

  const handleCustomDateApplyClick = () => {
    if (!Object.values(selectedCustomDate).some((item) => item === null)) {
      setSelectedDate(selectedCustomDate);
      setAnchorEl(null);
      setPrevDateFilterType('Custom');
      handleDateChange(selectedCustomDate, handleDatePickerClick);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (dateFilterType === 'Custom') {
      setSelectedCustomDate(selectedDate);

      setDateFilterType(prevDateFilterType);
    }
  };

  const chipLabel = (() => {
    if (
      (isCustomDatePickerOpen && !selectedDate.startDate) ||
      !selectedDate.endDate
    ) {
      return 'Custom';
    } else if (selectedDate.startDate?.isSame(selectedDate.endDate, 'day')) {
      return `${selectedDate.startDate?.format('YYYY.MM.DD.')}`;
    } else {
      return `${selectedDate.startDate?.format('YYYY.MM.DD.')} ~ ${selectedDate.endDate?.format('YYYY.MM.DD.')}`;
    }
  })();

  const isDateFilterOpen = Boolean(anchorEl);

  const isDefaultDate =
    selectedDate.startDate?.isSame(defaultDate?.startDate) &&
    selectedDate.endDate?.isSame(defaultDate?.endDate);

  return {
    anchorEl,
    chipLabel,
    dateFilterType,
    handleButtonClick,
    handleClose,
    handleCustomDateApplyClick,
    handleCustomEndDateChange,
    handleCustomStartDateChange,
    handleDatePickerClick,
    isCustomDatePickerOpen,
    isDateFilterOpen,
    selectedCustomDate,
    selectedDate,
    isEnableCustomData:
      !!selectedCustomDate.startDate && !!selectedCustomDate.endDate,
    isOpenEndDate:
      !!selectedCustomDate?.startDate && !selectedCustomDate.endDate,
    isOpenStartDate: !selectedDate.startDate,
    isDefaultDate
  };
};
