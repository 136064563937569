const go = (
  iter: Iterable<any>,
  ...fns: ((iter: Iterable<any> | any) => any)[]
) => reduce((acc, f) => f(acc), fns, iter);

const join = <T>(sep: string, iter: Iterable<T>): string => {
  return reduce((acc, cur) => acc + sep + cur, iter);
};

const shorten = (sep: string, data: string[]): string => {
  return join(sep, [data[0], data[data.length - 1]]);
};

function reduce<T, U>(
  reducer: (accumulator: U, value: T) => U,
  iterable: Iterable<T>,
  initialValue?: U
): U {
  const iterator = iterable[Symbol.iterator]() as IterableIterator<T>;
  let accumulator = initialValue ?? iterator.next().value;

  for (const value of iterator) {
    accumulator = reducer(accumulator, value);
  }

  return accumulator;
}

const filter = function* <T>(
  predicate: (value: T) => boolean,
  iter: Iterable<T>
) {
  const iterator = iter[Symbol.iterator]() as IterableIterator<T>;

  for (const el of iterator) {
    if (predicate(el)) yield el;
  }
};

const groupBy = <T>(mapper: (value: T) => string, iter: Iterable<T>) => {
  return reduce(
    (acc, cur) => {
      return { ...acc, [mapper(cur)]: [...(acc[mapper(cur)] ?? []), cur] };
    },
    iter,
    {} as Record<string, T[]>
  );
};

const sortBy = <T>(
  comparator: (a: T, b: T) => number,
  iterable: Iterable<T>
): T[] => {
  const array = Array.from(iterable);
  return array.sort(comparator);
};

function* map<T, F>(mapper: (value: T) => F, iter: Iterable<T>) {
  for (const a of iter) {
    yield mapper(a);
  }
}

function* entries<T>(obj: Record<string, T>) {
  for (const k in obj) {
    yield { key: k, value: obj[k] };
  }
}

export { map, join, entries, sortBy, shorten, reduce, groupBy, go, filter };
