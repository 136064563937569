import { useCaseListPageContext } from '@Features/Explorer/Context';
import { useEffect } from 'react';
import { useInitialDataState } from '@Shared/Atoms/InitialData/useInitialDataState';
import { MODULE_EXPLORER_TYPE } from '@Shared/Constants/initialData';
import { SolutionType } from '@Features/Explorer/Shared/Types/designCase';

const getDefaultSolutionFromImportHost = (importHost: string) => {
  if (importHost.includes('crown')) return 'CROWN';
  if (importHost.includes('modeler')) return 'MODELER';

  return 'ALL';
};

export const useCaseListSolutionFilter = () => {
  const { queryOption, setQueryOption } = useCaseListPageContext();
  const { initialData } = useInitialDataState();
  const solution =
    initialData.importType === MODULE_EXPLORER_TYPE.IMPORTER
      ? 'ALL'
      : getDefaultSolutionFromImportHost(initialData.importHost ?? '');
  const isDisabled = initialData.importType === MODULE_EXPLORER_TYPE.OPENER;

  const changeSolutionQueryOption = (solution: SolutionType) => {
    setQueryOption((prev) => ({ ...prev, solution }));
  };

  const changePlatformQueryOption = (showBatchCase: boolean) => {
    setQueryOption((prev) => ({
      ...prev,
      platform: showBatchCase ? undefined : 'GENERAL'
    }));
  };

  useEffect(() => {
    setQueryOption((prev) => ({ ...prev, solution }));
  }, []);

  return {
    solution: queryOption.solution ?? 'ALL',
    platform: queryOption.platform,
    isDisabled,
    changeSolutionQueryOption,
    changePlatformQueryOption
  };
};
