import { ComponentType } from 'react';
import { SessionExpiredDialogue } from './SessionExpiredDialogue';

export const withSessionExpiredDialogue = (Component: ComponentType<any>) => {
  return function ComponentWithSessionExpiredDialogue(props: any) {
    return (
      <>
        <Component {...props} />
        <SessionExpiredDialogue />
      </>
    );
  };
};
