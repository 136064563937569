import { Stack } from '@mui/material';
import { theme, Typography } from '@imago-cloud/design-system';
import { useTranslation } from 'react-i18next';

export const CaseListHeader = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        p: '15px 46px 15px 40px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: theme.palette.divider
      }}
    >
      <Typography variant={'Subtitle_Semibold14'} width={'148px'}>
        {t('meditLink:case_selection.title_case_name')}
      </Typography>
      <Typography variant={'Subtitle_Semibold14'} width={'480px'}>
        {t('meditLink:case_selection.title_status')}
      </Typography>
      <Typography variant={'Subtitle_Semibold14'} width={'320px'}>
        {t('meditLink:case_selection.title_patient_name')}
      </Typography>
    </Stack>
  );
};
