import React, { createContext, useEffect, useMemo, useState } from 'react';
import { IListContextBase, IQueryOption } from '../Shared/Types/listContext';
import { TDesignCase } from '../Shared/Types/designCase';
import { DATE_TYPE_NAME } from '../Shared/Constants/caseDateType';

export const CaseListPageContext =
  createContext<IListContextBase<TDesignCase | null> | null>(null);

interface CaseListPageProviderProps {
  children: React.ReactNode;
}

const CaseListPageProvider = ({ children }: CaseListPageProviderProps) => {
  const [queryOption, setQueryOption] = useState<IQueryOption>({
    dateType: DATE_TYPE_NAME.MODIFIED_TIME,
    sort: {
      target: DATE_TYPE_NAME.MODIFIED_TIME,
      isDescending: true
    },
    solution: 'ALL',
    limit: 24
  });
  const [selectedItem, setSelectedItem] = useState<TDesignCase | null>(null);

  useEffect(() => {
    setSelectedItem(null);
  }, [queryOption]);

  const value = useMemo(
    () => ({
      queryOption,
      setQueryOption,
      selectedItem,
      setSelectedItem
    }),
    [queryOption, setQueryOption, selectedItem, setSelectedItem]
  );

  return (
    <CaseListPageContext.Provider value={value}>
      {children}
    </CaseListPageContext.Provider>
  );
};

export default CaseListPageProvider;
