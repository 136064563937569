import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '@Shared/Apis/axiosInstance';
import { USER_SYSTEM_SETTING_KEY } from '@Shared/Constants/reactQueryKey';
import { USER_SYSTEM_SETTING_API } from '@Shared/Constants/apiUrl';

type TSetting = {
  language: 'EN' | 'JA';
  dentalNotation: 'FDI' | 'ADA';
  dateFormat: 'YYYY-MM-DD' | 'MM-DD-YYYY' | 'DD-MM-YYYY';
};

export const useSystemSetting = () => {
  return useQuery<{
    data: TSetting;
  }>({
    queryKey: [USER_SYSTEM_SETTING_KEY],
    queryFn: () =>
      axiosInstance.get(USER_SYSTEM_SETTING_API).then((res) => res.data)
  });
};
