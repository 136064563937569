import { Label } from '@imago-cloud/design-system';
import React from 'react';

export const BatchCaseLabel = () => {
  return (
    <Label
      variant="ghost"
      color="success"
      sx={{
        minWidth: '18px !important',
        width: '18px !important',
        height: '18px !important',
        borderRadius: '30px',
        padding: '5px 6px',
        fontSize: '11px'
      }}
    >
      B
    </Label>
  );
};
