import { useIntersectionObserver } from '@Shared/Hooks/useIntersectionObserver';
import { useDesignCaseListInfiniteQuery } from '../Apis/caseList';
import { useCaseListPageContext } from '../../Context';

export const useCaseListIntersectionObserver = (
  target: React.RefObject<HTMLElement>
) => {
  const { queryOption } = useCaseListPageContext();
  const { fetchNextPage, hasNextPage, isFetching } =
    useDesignCaseListInfiniteQuery(queryOption);

  useIntersectionObserver(
    {
      target: target,
      enabled: hasNextPage,
      threshold: 0,
      isFetching
    },
    () => fetchNextPage()
  );
};
