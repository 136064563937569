import axios from 'axios';

const meditAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MEDIT_RESOURCE_URL,
  headers: {
    'x-meditlink-client-id': process.env.REACT_APP_X_MEDITLINK_CLIENT_ID ?? '',
    'Content-Type': 'application/json'
  }
});

const meditAuthAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MEDIT_AUTH_URL,
  headers: {
    Authorization: process.env.REACT_APP_MEDIT_AUTH_AUTHORIZATION ?? ''
  }
});

const setMeditAxiosInstanceHeader = ({
  accessToken,
  groupUUID
}: {
  accessToken?: string;
  groupUUID?: string;
}) => {
  if (accessToken) {
    meditAxiosInstance.defaults.headers.common['Authorization'] =
      `Bearer ${accessToken}`;
  }
  if (groupUUID) {
    meditAxiosInstance.defaults.headers.common['x-meditlink-group-uuid'] =
      groupUUID;
  }
};

export {
  meditAuthAxiosInstance,
  meditAxiosInstance,
  setMeditAxiosInstanceHeader
};
