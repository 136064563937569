import { TMeditFile } from '../../Shared/Types/meditFileType';
import { Checkbox, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useMeditSelectedFileListState } from '../../Shared/Atoms/meditSelectedFileList/useMeditSelectedFileListState';

const MAX_IMPORT_FILE_LENGTH = 44;

export const MeditFileListItem = (props: TMeditFile) => {
  const { fileList, appendFileToList, deleteFileFromList } =
    useMeditSelectedFileListState();
  const isChecked =
    fileList.findIndex((selectedUUID) => props.uuid === selectedUUID) >= 0;
  const isDisabled = !isChecked && fileList.length >= MAX_IMPORT_FILE_LENGTH;

  const selectFile = () => {
    if (isChecked) deleteFileFromList(props.uuid);
    else if (!isDisabled) {
      appendFileToList(props.uuid);
    }
  };
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '20px',
        p: '12px 20px',
        alignItems: 'center',
        height: '60px',
        cursor: 'pointer',
        borderRadius: '8px',
        color: isChecked ? 'blue.500' : 'grey.900',
        backgroundColor: isChecked ? 'blue.10' : 'transparent',
        '&:hover': {
          backgroundColor: isDisabled ? 'inherit' : 'grey.100'
        },
        '&:active': {
          backgroundColor: isDisabled ? 'inherit' : 'grey.200'
        }
      }}
      onClick={selectFile}
    >
      <Checkbox
        size={'36'}
        variant={'basic'}
        checked={isChecked}
        disabled={isDisabled}
      />
      <Typography
        variant={'Body16'}
        sx={{
          color: isDisabled ? theme.palette.action.disabled : 'inherit'
        }}
      >
        {props.name.substring(0, props.name.lastIndexOf('.'))}
      </Typography>
    </Stack>
  );
};
