import { useCaseListPageContext } from '../../Context';
import { useDesignCaseListInfiniteQuery } from '../Apis/caseList';
import { useMemo } from 'react';

export type UseGetCaseList = ReturnType<typeof useGetCaseList>;

export const useGetCaseList = () => {
  const { queryOption } = useCaseListPageContext();

  const {
    data: rawDesignCaseList,
    isFetching,
    isError
  } = useDesignCaseListInfiniteQuery(queryOption);

  // cbt 이후에 importPlatformFilter(importHost)로 host별 caseList 필터링 추가하기

  const designCaseList = useMemo(() => {
    return (
      rawDesignCaseList?.pages
        ?.flatMap((page) => page.data)
        .filter(
          (designCase) =>
            designCase.products.filter(
              (product) => product.productType === 'VIRTUAL_CROWN'
            ).length === 0
        ) ?? []
    );
  }, [rawDesignCaseList]);

  return {
    designCaseList,
    isFetching,
    isError
  };
};
