import { Stack } from '@mui/material';
import { Loading } from '@imago-cloud/design-system';

export const AppLoading = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: '9999'
      }}
    >
      <Loading size="44" type="basic" />
    </Stack>
  );
};
