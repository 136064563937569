export const getCaseStatus = (label: string) => {
  switch (label) {
    case 'draft':
      return { text: 'Waiting to send', value: 'Waiting to send' };
    case 'waitAccept':
      return { text: 'Pending', value: 'Pending' };
    case 'canceled':
      return { text: 'Canceled', value: 'Canceled' };
    case 'refused':
      return { text: 'Refused', value: 'Refused' };
    case 'waitDesign':
      return { text: 'Waiting design', value: 'Waiting design' };
    case 'waitConfirmDesign':
      return {
        text: 'Design plan confirming',
        value: 'Design plan confirming'
      };
    case 'waitDesignFile':
      return { text: 'Design uploading', value: 'Design uploading' };
    case 'waitConfirmDesignFile':
      return { text: 'Design confirming', value: 'Design confirming' };
    case 'waitDelivery':
      return { text: 'Producing', value: 'Producing' };
    case 'waitConfirmDelivery':
      return { text: 'Shipping', value: 'Shipping' };
    case 'finished':
      return { text: 'Finished', value: 'Finished' };
    default:
      return { text: '', value: '' };
  }
};
