import { useShining3DCookies } from '../../Shared/Hooks/useShining3DCookies';
import { useSelectedOrderCaseState } from '../../Shared/Atoms/selectedOrderCase/useSelectedOrderCaseState';
import { useSelectedInstitutionState } from '../../Shared/Atoms/selectedInstitution/useSelectedInstitutionState';
import { useQuery } from '@tanstack/react-query';
import { UNZIPPED_SHINING3D_ORDER_CASE } from '../../Shared/Constants/reactQueryKeys';
import { TOrderCase } from '../../Shared/Types/orderCase';
import { TUserFactory } from '../../Shared/Types/factory';
import { getUnzippedOrderCaseFile } from './shining3DFileApis';

const useUnzippedOrderCaseFile = () => {
  const { csrf, connectionToken } = useShining3DCookies();
  const { selectedOrderCase } = useSelectedOrderCaseState();
  const { selectedInstitution } = useSelectedInstitutionState();

  return useQuery(
    [UNZIPPED_SHINING3D_ORDER_CASE],
    () =>
      getUnzippedOrderCaseFile({
        csrf,
        orderCase: selectedOrderCase as TOrderCase,
        institution: selectedInstitution as TUserFactory,
        connectionToken
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false
    }
  );
};

export { useUnzippedOrderCaseFile };
