import { useCookies } from 'react-cookie';
import {
  AUTH_CSRF_COOKIE,
  CALLBACK_CODE_COOKIE,
  CONNECTION_TOKEN_COOKIE,
  OAUTH_ACCESS_TOKEN,
  OAUTH_REFRESH_TOKEN
} from '../Constants/cookies';

export const useShining3DCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const removeCookies = (cookieList: string[]) => {
    cookieList.forEach((cookie) => {
      removeCookie(cookie, {
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true
      });
    });
  };

  const removeNonAuthCookies = () => {
    const nonAuthCookies = [OAUTH_ACCESS_TOKEN, CALLBACK_CODE_COOKIE];
    removeCookies(nonAuthCookies);
  };

  const removeAllCookie = () => {
    const allCookies = [
      CONNECTION_TOKEN_COOKIE,
      AUTH_CSRF_COOKIE,
      CALLBACK_CODE_COOKIE,
      OAUTH_REFRESH_TOKEN,
      OAUTH_ACCESS_TOKEN
    ];
    removeCookies(allCookies);
  };

  const setCookies = (
    cookieList: { cookieName: string; cookieValue: string }[]
  ) => {
    cookieList.forEach(({ cookieName, cookieValue }) => {
      setCookie(cookieName, cookieValue, {
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true
      });
    });
  };

  return {
    callbackCode: cookies[CALLBACK_CODE_COOKIE],
    connectionToken: cookies[CONNECTION_TOKEN_COOKIE],
    accessToken: cookies[OAUTH_ACCESS_TOKEN],
    refreshToken: cookies[OAUTH_REFRESH_TOKEN],
    csrf: cookies[AUTH_CSRF_COOKIE],
    removeNonAuthCookies,
    removeAllCookie,
    setCookies,
    removeCookies
  };
};
