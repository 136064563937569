import { useMeditCaseState } from '../../Shared/Atoms/meditCase/useMeditCaseState';
import { useMeditFileListWithUUID } from '../../Shared/Apis/meditLinkQueries';
import { Stack } from '@mui/material';
import { Loading } from '@imago-cloud/design-system';

import { TMeditFile } from '../../Shared/Types/meditFileType';
import { useMemo } from 'react';
import { NoFileListContent } from './NoFileListContent';
import { MeditFileListItem } from './MeditFileListItem';

export const MeditFileList = () => {
  const { meditCase } = useMeditCaseState();
  const { data, isFetching } = useMeditFileListWithUUID(meditCase?.uuid ?? '');
  const fileList = useMemo(() => {
    return (
      data?.files?.filter(
        (el: TMeditFile) =>
          el.fileType === 'SCAN_DATA' && el.name.includes('.meditMesh')
      ) || []
    );
  }, [data]);

  if (isFetching)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Loading size="44" type="basic" />
      </Stack>
    );

  return (
    <>
      {fileList.length ? (
        fileList.map((file: TMeditFile) => (
          <MeditFileListItem key={file.uuid} {...file} />
        ))
      ) : (
        <NoFileListContent />
      )}
    </>
  );
};
