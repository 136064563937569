import { GET_USER_API } from '../Constants/apiUrl';
import { useQuery } from '@tanstack/react-query';
import { USER_INFO_KEY } from '../Constants/reactQueryKey';
import { axiosInstance } from './axiosInstance';

export const getUserInfo = () =>
  axiosInstance.get(GET_USER_API).then((res) => {
    const {
      user: { _id }
    } = res.data || {};
    axiosInstance.defaults.headers.common['x-user-id'] = `${_id}`;
    return res.data;
  });

export const useGetUserInfo = () => {
  return useQuery({
    queryKey: [USER_INFO_KEY],
    queryFn: getUserInfo,
    staleTime: Infinity
  });
};
